<template>
  <div>
    <b-card class="mb-2">
      <template #header>
          <h5 class="mb-0">{{ translations.section_title }}</h5>
      </template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="flex-1">
          <b-alert class="mb-0" show variant="warning">
            {{ translations.alert_text }}
          </b-alert>
        </div>
        <div class="ml-4">
          <b-button variant="primary" @click="handleAddOrEdit">
            {{ translations.btn_new }}
          </b-button>
        </div>
      </div>
      <b-row v-if="lineOfBusinesses.length > 0" class="mt-4">
        <b-col cols="12">
          <table class="table table-striped table-bordered responsive">
            <thead>
              <tr>
                <th scope="col" class="col-2"></th>
                <th scope="col" class="col-2">DPT</th>
                <th scope="col" class="col-2">Bloom</th>
                <th scope="col" class="col-2">Mind</th>
                <th scope="col" class="col-2">Move</th>
                <th scope="col" class="col-2">DPT_GO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="lob in lineOfBusinesses" :key="lob.id">
                <td class="align-middle">
                  <ul class="list-unstyled">
                    <li>
                      <b>{{ translations.field_name }}: </b>
                      <a class="btn-link" @click="handleAddOrEdit(lob)">
                        {{ lob.line_of_business_name }}
                      </a>
                    </li>
                    <li>
                      <b>{{ translations.field_consortium }}: </b>
                      {{ lob.consortium_name }} {{ lob.consortium_id ? `(${lob.consortium_id})` : '' }}
                    </li>
                    <li><b>{{ translations.field_salesforce_id }}:</b> {{ lob.salesforce_id }}</li>
                  </ul>
                </td>
                <td class="align-middle">
                  <ul class="list-unstyled">
                    <li><b>{{ translations.field_salesforce_program_id }}:</b> {{ lob.dpt_salesforce_id }}</li>
                    <li><b>{{ translations.field_technical_launch_date }}:</b> {{ dateToDisplay(lob.dpt_technical_launch_date) }}</li>
                    <li><b>{{ translations.field_marketing_launch_date }}:</b> {{ dateToDisplay(lob.dpt_marketing_launch_date) }}</li>
                  </ul>
                </td>
                <td class="align-middle">
                  <ul class="list-unstyled">
                    <li><b>{{ translations.field_salesforce_program_id }}:</b> {{ lob.bloom_salesforce_id }}</li>
                    <li><b>{{ translations.field_technical_launch_date }}:</b> {{ dateToDisplay(lob.bloom_technical_launch_date) }}</li>
                    <li><b>{{ translations.field_marketing_launch_date }}:</b> {{ dateToDisplay(lob.bloom_marketing_launch_date) }}</li>
                  </ul>
                </td>
                <td class="align-middle">
                  <ul class="list-unstyled">
                    <li><b>{{ translations.field_salesforce_program_id }}:</b> {{ lob.mind_salesforce_id }}</li>
                    <li><b>{{ translations.field_technical_launch_date }}:</b> {{ dateToDisplay(lob.mind_technical_launch_date) }}</li>
                    <li><b>{{ translations.field_marketing_launch_date }}:</b> {{ dateToDisplay(lob.mind_marketing_launch_date) }}</li>
                  </ul>
                </td>
                <td class="align-middle">
                  <ul class="list-unstyled">
                    <li><b>{{ translations.field_salesforce_program_id }}:</b> {{ lob.move_salesforce_id }}</li>
                    <li><b>{{ translations.field_technical_launch_date }}:</b> {{ dateToDisplay(lob.move_technical_launch_date) }}</li>
                    <li><b>{{ translations.field_marketing_launch_date }}:</b> {{ dateToDisplay(lob.move_marketing_launch_date) }}</li>
                  </ul>
                </td>
                <td class="align-middle">
                  <ul class="list-unstyled">
                    <li><b>{{ translations.field_salesforce_program_id }}:</b> {{ lob.dpt_go_salesforce_id }}</li>
                    <li><b>{{ translations.field_technical_launch_date }}:</b> {{ dateToDisplay(lob.dpt_go_technical_launch_date) }}</li>
                    <li><b>{{ translations.field_marketing_launch_date }}:</b> {{ dateToDisplay(lob.dpt_go_marketing_launch_date) }}</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <div v-if="lineOfBusinesses.length < total" class="row pt-2">
        <div class="col-12 d-flex justify-content-center">
          <b-pagination :disabled="loading"
          @change="changePage"
          v-model="currentPage"
          :total-rows="total"
          :per-page="itemsPerPage"
          size="md"/>
        </div>
      </div>
    </b-card>
    <modal-add-or-edit
    :isVisible="isModalVisible"
    :client-id="clientId"
    :line-of-business="selectedToEdit"
    @refetch="getLineOfBusinesses"
    @update:isVisible="isModalVisible = $event" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import translations from '@/translations';
import service from '@/services/eligibility-service';
import { parseResponseError } from '@/http/parsers/parse_response';
import modalAddOrEdit from './modalAddOrEdit.vue';

export default {
  name: 'ClientEligibilityLineOfBusiness',
  components: {
    modalAddOrEdit,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      translations: translations.client_eligibility.line_of_business,
      isModalVisible: false,
      loading: false,
      lineOfBusinesses: [],
      selectedToEdit: null,
      itemsPerPage: 5,
      currentPage: 1,
      total: 0,
    };
  },
  async beforeMount() {
    await this.getLineOfBusinesses();
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.getLineOfBusinesses();
    },
    async getLineOfBusinesses() {
      try {
        this.loading = true;
        const params = {
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        };

        const { lobs, total } = await service.getLineOfBusinesses(this.clientId, params);
        this.lineOfBusinesses = lobs;
        this.total = total;
      } catch (err) {
        this.$noty.error(`${this.translations.error_get_line_of_businesses}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
    handleAddOrEdit(lob) {
      this.selectedToEdit = cloneDeep(lob);
      this.isModalVisible = true;
    },
    dateToDisplay(date) {
      return date && date.length > 0 ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : null;
    },
  },
};
</script>
