<template>
  <div>
     <b-card class="mb-2">
        <template #header>
           <h5 class="mb-0">Payers</h5>
        </template>
        <table class="table">
           <thead class="thead-light">
              <tr>
                 <th scope="col" class="col-md-2">
                    <b>Name</b>
                 </th>
                 <th scope="col"  class="col-md-2">
                    <b>Group IDs</b>
                 </th>
                 <th scope="col"  class="col-md-2">
                    <b>Beneficiary Type</b>
                 </th>
                 <th scope="col"  class="col-md-2">
                    <div id='info-export-orders'>
                       <b>Relationship Subs</b>
                       <b-badge variant="outline" pill  v-b-tooltip.html :title="relationshipInfo">
                          <feather type="info"/>
                       </b-badge>
                    </div>
                 </th>
                 <th scope="col-1"  class="col-md-2" ></th>
              </tr>
           </thead>
           <tbody>
              <tr v-if="!payers.length">
                 <td
                    aria-colspan="2"
                    colspan="2">
                    No payers added yet
                 </td>
              </tr>
              <template v-else>
                 <tr
                    v-for="(payer, payerIndex) in payers"
                    :key="`info-${payerIndex}`">
                    <td class="align-middle">
                       {{ payer.system_payer.payer_name }} (ID: {{ payerRteId(payer) }})
                    </td>
                    <td class="align-middle">
                       {{ getGroupIdsAsLabel(payer.group_ids) }}
                    </td>
                    <td class="align-middle">
                       {{ getBeneficiaryLabel(payer.beneficiary_types) }}
                    </td>
                    <td class="align-middle">
                       {{ getRelationshipsAsLabel(payer.relationships) }}
                    </td>
                    <td class="align-middle">
                      <b-button
                          type="button"
                          class="align-self-end w-100"
                          variant="outline-secondary"
                          :disabled="isRemovingPayer"
                          @click="openEditModal(payerIndex, payer)">
                          <b-spinner
                             v-if="isRemovingPayer"
                             label="Loading..."
                             small />
                          <feather
                             v-else
                             type="edit" />
                       </b-button>
                       <b-button
                          type="button"
                          class="align-self-end w-100 mt-2"
                          variant="outline-danger"
                          :disabled="isRemovingPayer"
                          @click="removePayer(payerIndex, payer.id)">
                          <b-spinner
                             v-if="isRemovingPayer"
                             label="Loading..."
                             small />
                          <feather
                             v-else
                             type="trash" />
                       </b-button>
                    </td>
                 </tr>
              </template>
              <tr>
                 <td class="align-middle">
                    <multiselect
                       v-model="systemPayer"
                       placeholder="System Payer"
                       :options="systemPayers"
                       :multiple="false"
                       track-by="id"
                       label="visibleLabel" />
                 </td>
                 <td class="align-middle">
                    <multiselect
                       v-model="groupIds"
                       tag-placeholder="Add a new payer group ID"
                       placeholder="Group IDs"
                       :options="eligibleGroupIds"
                       track-by="code"
                       label="name"
                       :multiple="true"
                       :taggable="true"
                       @tag="addEligibleGroupId($event)" />
                 </td>
                 <td class="align-middle">
                    <multiselect
                       v-model="beneficiaryTypes"
                       class="multiselect"
                       :options="beneficiaryOptions"
                       track-by="value"
                       label="text"
                       :multiple="true" />
                 </td>
                 <td class="align-middle">
                    <multiselect
                       v-model="relationships"
                       tag-placeholder="Add a new payer relationship"
                       placeholder="Relationship"
                       :options="eligibleRelationships"
                       track-by="code"
                       label="name"
                       :multiple="true"
                       :taggable="true"
                       @tag="addEligibleRelationships($event)" />
                 </td>
                 <td class="align-middle">
                    <b-button
                       type="button"
                       class="align-self-end w-100"
                       :disabled="$v.$invalid || isAddingPayer"
                       variant="primary"
                       @click="addPayer">
                       <b-spinner
                          v-if="isAddingPayer"
                          label="Loading..."
                          variant="light"
                          small />
                       <feather
                          v-else
                          type="plus" />
                    </b-button>
                 </td>
              </tr>
           </tbody>
        </table>
     </b-card>
     <b-modal
        id="modal-edit-payer"
        v-model="showEditModal"
        ok-title='Edit'
        size="lg"
        title="Edit Payer"
        @ok="editPayer"
        @cancel="dissmissEditModal"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close>
        <b-col>
           <b-col cols="6">
              <b-form-group id="group-group_ids"
                 label-for="select-group_ids"
                 description="">
                 <template #label>
                    Group IDs:
                 </template>
                 <multiselect
                    v-model="editGroupIds"
                    tag-placeholder="Add a new payer group ID"
                    placeholder="Group IDs"
                    :options="eligibleGroupIds"
                    track-by="code"
                    label="name"
                    :multiple="true"
                    :taggable="true"
                    @tag="addEditEligibleGroupId($event)" />
              </b-form-group>
           </b-col>
           <b-col cols="6">
              <b-form-group id="group-beneficiary_types"
                 label-for="select-beneficiary_types"
                 description="">
                 <template #label>
                    Beneficiary Types:
                 </template>
                 <multiselect
                    v-model="editBeneficiaryTypes"
                    class="multiselect"
                    :options="beneficiaryOptions"
                    track-by="value"
                    label="text"
                    :multiple="true" />
              </b-form-group>
           </b-col>
           <b-col cols="6">
              <b-form-group id="group-relationship"
                 label-for="select-relationship"
                 description="">
                 <template #label >
                    Relationship:
                    <b-badge variant="outline" pill class="float-right" v-b-tooltip.html :title="relationshipInfo">
                       <feather type="info"/>
                    </b-badge>
                 </template>
                 <multiselect
                    v-model="editRelationships"
                    tag-placeholder="Add a new payer relationship"
                    placeholder="Relationship"
                    :options="eligibleRelationships"
                    track-by="code"
                    label="name"
                    :multiple="true"
                    :taggable="true"
                    @tag="addEditEligibleRelationships($event)" />
              </b-form-group>
           </b-col>
        </b-col>
     </b-modal>
  </div>
</template>

<script>
import * as R from 'ramda';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import { required } from 'vuelidate/lib/validators';
import { beneficiaryTypes } from '@/scripts/definitions';

export default {
  components: {
    Multiselect,
  },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    payers: {
      type: Array,
      default: () => [],
    },
    fileConfigs: {
      type: Array,
      default: () => [],
    },
    eligibility: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      relationshipInfo: 'Possible codes available for relationship to Subscriber <a href="https://www.stedi.com/edi/x12/element/1069" target="_blank">here</a>', // eslint-disable-line max-len

      beneficiaryType: '',
      beneficiaryOptions: beneficiaryTypes.map(o => ({ value: o.value, text: o.label, disabled: o.disabled })),

      isAddingPayer: false,
      isRemovingPayer: false,

      systemPayer: {},
      systemPayers: [],

      // new payer form
      system_payer_id: '',
      groupIds: [],
      relationships: [],
      beneficiaryTypes: [],

      // edit payer modal
      showEditModal: false,
      payerToEdit: null,
      editGroupIds: [],
      editRelationships: [],
      editBeneficiaryTypes: [],
    };
  },
  computed: {
    rules() {
      return {
        system_payer_id: { required },
        payerIsUnique(newPayer) {
          return !this.payers.some(p => p.system_payer_id === newPayer.systemPayer.id);
        },
      };
    },
    eligibleGroupIds() {
      const ids = [];

      this.payers.forEach(payer => {
        if (payer.group_ids) {
          ids.push(payer.group_ids);
        }
      });

      ids.push(this.groupIds.map(tag => tag.code));

      return R.uniq(ids.flat()).map(id => ({
        name: id,
        code: id,
      }));
    },
    eligibleRelationships() {
      const ids = [];

      this.payers.forEach(payer => {
        if (payer.relationships) {
          ids.push(payer.relationships);
        }
      });

      ids.push(this.relationships.map(tag => tag.code));

      return R.uniq(ids.flat()).map(id => ({
        name: id,
        code: id,
      }));
    },
  },
  validations() {
    return this.rules;
  },
  mounted() {
    this.getSystemPayers();
  },
  watch: {
    systemPayer() {
      this.system_payer_id = this.systemPayer.id.toString();
    },
    eligibility: {
      handler() {
        this.getSystemPayers();
      },
      deep: true,
    },
  },
  methods: {
    defaultPayer() {
      return {
        system_payer_id: '',
        groupIds: [],
        relationships: [],
        beneficiaryTypes: [],
      };
    },
    resetForm() {
      this.system_payer_id = '';
      this.groupIds = [];
      this.relationships = [];
      this.beneficiaryTypes = [];

      this.resetEditForm();
    },
    resetEditForm() {
      this.showEditModal = false;
      this.payerToEdit = null;
      this.editGroupIds = [];
      this.editRelationships = [];
      this.editBeneficiaryTypes = [];
    },
    payerRteId(payer) {
      if (this.eligibility.configs.availity) {
        return payer.system_payer.rte_payer_id;
      }
      if (this.eligibility.configs.waystar) {
        return payer.system_payer.rte_payer_waystar_id;
      }
      if (this.eligibility.configs.change_healthcare) {
        return payer.system_payer.rte_payer_change_healthcare_id;
      }
      return payer.system_payer.rte_payer_id;
    },
    async addPayer() {
      if (!this.$v.payerIsUnique) return;

      this.isAddingPayer = true;
      try {
        const { data } = await axios.post(`v1/clients/${this.client.id}/eligibility/payers`, {
          system_payer_id: this.system_payer_id,
          group_ids: this.groupIds.map(id => id.name),
          relationships: this.relationships.map(id => id.name),
          beneficiary_types: this.beneficiaryTypes.map(id => id.value),
        });
        this.$emit('payer-add', data.data);
        this.$noty.success('Payer added!');
        this.resetForm();
      } catch (error) {
        this.$noty.error('Failed to add payer', error);
      } finally {
        this.isAddingPayer = false;
      }
    },
    async editPayer() {
      try {
        const { data } = await axios.put(`v1/clients/${this.client.id}/eligibility/payers/${this.payerToEdit.id}`, {
          group_ids: this.editGroupIds.map(id => id.name),
          relationships: this.editRelationships.map(id => id.name),
          beneficiary_types: this.editBeneficiaryTypes.map(id => id.value),
        });

        this.$emit('payer-updated', data.data);
        this.$noty.success('Payer updated!');
      } catch (error) {
        this.$noty.error('Failed to edit payer', error);
      } finally {
        this.resetEditForm();
      }
    },
    openEditModal(index, payer) {
      this.editGroupIds = payer.group_ids?.map(id => ({
        name: id,
        code: id,
      })) || [];
      this.editBeneficiaryTypes = payer.beneficiary_types?.map(id => this.beneficiaryOptions.find(o => o.value === id)) || [];
      this.editRelationships = payer.relationships?.map(id => ({
        name: id,
        code: id,
      })) || [];
      this.payerToEdit = payer;
      this.showEditModal = true;
    },
    dissmissEditModal() {
      this.resetEditForm();
    },
    async removePayer(index, payerId) {
      const payerUsedInProvider = this.isPayerUsed(payerId);
      if (payerUsedInProvider) {
        await this.$bvModal.msgBoxOk(`Cannot delete: payer used in eligibility file provider "${payerUsedInProvider}"`);
        return;
      }

      const result = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this payer?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        centered: true,
      });

      if (!result) return;

      this.isRemovingPayer = true;

      try {
        await axios.delete(`v1/clients/${this.client.id}/eligibility/payers/${payerId}`);

        this.$emit('payer-remove', index);
        this.$noty.success('Payer removed!');
      } catch (error) {
        this.$noty.error('Failed to remove payer', error);
      } finally {
        this.isRemovingPayer = false;
      }
    },
    async getSystemPayers() {
      const { data } = await axios.get('v1/finance/system-payers');
      if (data && data.data.system_payers) {
        this.systemPayers = data.data.system_payers.map(sp => ({
          ...sp,
          visibleLabel: `${sp.payer_name} (ID: ${sp.rte_payer_id})`,
        }));

        if (this.eligibility.configs.availity) {
          this.systemPayers = this.systemPayers
            .filter(systemPayer => systemPayer.rte_payer_id)
            .map(sp => ({
              ...sp,
              visibleLabel: `${sp.payer_name} (ID: ${sp.rte_payer_id})`,
            }));
        } else if (this.eligibility.configs.waystar) {
          this.systemPayers = this.systemPayers
            .filter(systemPayer => systemPayer.rte_payer_waystar_id)
            .map(sp => ({
              ...sp,
              visibleLabel: `${sp.payer_name} (ID: ${sp.rte_payer_waystar_id})`,
            }));
        } else if (this.eligibility.configs.change_healthcare) {
          this.systemPayers = this.systemPayers
            .filter(systemPayer => systemPayer.rte_payer_change_healthcare_id)
            .map(sp => ({
              ...sp,
              visibleLabel: `${sp.payer_name} (ID: ${sp.rte_payer_change_healthcare_id})`,
            }));
        }
      }
    },
    addEligibleGroupId(id) {
      const tag = {
        name: id,
        code: id,
      };

      this.groupIds.push(tag);

      return tag;
    },
    addEditEligibleGroupId(id) {
      const tag = {
        name: id,
        code: id,
      };

      this.editGroupIds.push(tag);

      return tag;
    },
    addEditEligibleRelationships(id) {
      const tag = {
        name: id,
        code: id,
      };

      this.editRelationships.push(tag);

      return tag;
    },
    addEligibleRelationships(id) {
      const tag = {
        name: id,
        code: id,
      };

      this.relationships.push(tag);

      return tag;
    },
    getRelationshipsAsLabel(ids) {
      return ids ? ids.join(', ') : '';
    },
    getGroupIdsAsLabel(ids) {
      return ids ? ids.join(', ') : '';
    },
    getBeneficiaryLabel(ids) {
      const textType = [];
      if (ids) {
        ids.forEach(id => {
          beneficiaryTypes.forEach(beneficiaryType => {
            if (beneficiaryType.value === id) {
              textType.push(beneficiaryType.label);
            }
          });
        });
      }
      return textType ? textType.join(', ') : '';
    },
    isPayerUsed(payerId) {
      for (let i = 0; i < this.fileConfigs.length; i++) {
        const fileConfig = this.fileConfigs[i];
        if (fileConfig.payers) {
          for (let j = 0; j < fileConfig.payers.length; j++) {
            const payerConfig = fileConfig.payers[j];
            if (payerConfig.payer_id === payerId) {
              return fileConfig.provider_name;
            }
          }
        }
      }
      return null;
    },
  },
};
</script>
