<template>
  <div>
    <b-card class="my-4" body-class="p-0">

      <template #header>
        <h5 class="float-left">Multi tenant</h5>
      </template>

      <b-tabs>
        <b-tab title="ASO Groups" class="m-4">
          <aso-groups-tab :client="client" :eligibility="eligibility" :aso_form="aso_form" />
        </b-tab>

        <b-tab title="Aso Payer Groups" class="m-4">
          <aso-payer-groups-tab :client="client" :eligibility="eligibility" :payers="payers" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>

import AsoGroupsTab from '@/components/ClientEligibility/AsoGroupsTab.vue';
import AsoPayerGroupsTab from '@/components/ClientEligibility/AsoPayerGroupsTab.vue';

export default {
  components: {
    AsoGroupsTab,
    AsoPayerGroupsTab,
  },
  mixins: [],
  props: {
    client: {
      type: Object,
      default: () => { },
    },
    eligibility: {
      type: Object,
      default: () => { },
    },
    payers: {
      type: Array,
      default: () => [],
    },
    aso_form: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {};
  },
  computed: {},
  validations() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>
