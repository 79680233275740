<template>
  <b-card>
    <b-card-body class="pt-1 pl-2 pr-2 pb-2">
      <b-row v-if="!dryRun" class="mb-3">
        <b-col cols="3">
          <b-form-checkbox
            name="isQuarantineEnabled"
            v-model="isQuarantineEnabled">
            Quarantine Enabled
          </b-form-checkbox>
        </b-col>
      </b-row>
      <span class="mt-5">
        <b>Threshold Values</b>
        <b-button v-b-toggle.quarantine-configs @click="quarantineConfigsOpenHandler" class="ml-2 btn btn-info btn-sm">
          {{ quarantineConfigsOpen ? 'Hide' : 'Show' }} Values
        </b-button>
      </span>
      <b-collapse id="quarantine-configs">
        <b-row class="mt-4 pl-3 pr-3">
          <b-col cols="3" v-for="(config, i) in configs" :key="i">
            <b-form-group
              :label="config.label"
              :label-for="config.name"
              :key="config.name">
              <b-form-input
                :id="config.name"
                :name="config.name"
                min="0"
                :disabled="dryRun"
                @change="emitUpdate"
                :state="validateState(config.name)"
                type="number"
                v-model="customConfigs[config.name]"
                :placeholder="`${config.default_value}`"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'Configs',
  props: {
    configs: {
      type: Object,
      default: () => {},
    },
    client: {
      type: Object,
      default: () => {},
    },
    dryRun: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      quarantineConfigsOpen: false,
      isQuarantineEnabled: false,
      customConfigs: {},
      customConfigsValidation: {},
      emitUpdate: debounce(() => {
        if (this.dryRun) {
          return;
        }

        this.$emit('quarantine-config-update', this.buildEvent());
      }, 250),
    };
  },
  beforeMount() {
    this.isQuarantineEnabled = !!this.client?.is_quarantine_enabled;
    Object.entries(this.configs).forEach(([ key, config ]) => {
      if (config.default_value !== config.value) {
        this.customConfigs[key] = config.value;
      }
    });

    if (!this.dryRun) {
      this.emitUpdate();
    }
  },
  watch: {
    customConfigs: {
      handler() {
        this.emitUpdate();
      },
      deep: true,
    },
    isQuarantineEnabled: {
      handler() {
        this.emitUpdate();
      },
      deep: true,
    },
  },
  methods: {
    validateState(code) {
      if (!this.customConfigs[code]) {
        return null;
      }

      this.customConfigsValidation[code] = Number(this.customConfigs[code]) >= 0;
      return this.customConfigsValidation[code];
    },
    buildEvent() {
      const event = {
        // always send false to re-write any existing config sent from the parent
        filepathChanged: false,
        mapping: [],
        isValid: !Object.values(this.customConfigsValidation).some(value => value === false),
        configs: this.configs,
      };

      // only send update event if client already exists
      if (this.client) {
        event.isQuarantineEnabled = this.isQuarantineEnabled;
      }

      Object.keys(this.customConfigs).forEach(key => {
        if (this.isNumeric(this.customConfigs[key])) {
          event.mapping.push({
            uuid: this.configs[key].uuid,
            value: Number(this.customConfigs[key]),
          });
        }
      });

      return event;
    },
    quarantineConfigsOpenHandler() {
      this.quarantineConfigsOpen = !this.quarantineConfigsOpen;
    },
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
