<template>
  <b-modal
  id="modal-line-of-business-form"
  :title="lineOfBusiness?.id ? translations.modal_edit_title : translations.modal_add_title"
  size="lg"
  v-model="opened"
  @hide="handleClose">
    <b-row>
      <b-col cols="6">
        <b-form-group
        id="line_of_business_name"
        :invalid-feedback="handleError('form.line_of_business_name')"
        :state="isValid('form.line_of_business_name')"
        :label="translations.label_line_of_business_name">
          <b-form-input
          id="line_of_business_name"
          :disabled="loading"
          :placeholder="translations.label_line_of_business_name"
          v-model.trim="form.line_of_business_name"/>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
        id="salesforce_account_id"
        :label="translations.label_salesforce_account_id">
          <b-form-input
          id="salesforce_account_id"
          :disabled="loading"
          :placeholder="translations.placeholder_salesforce_account_id"
          v-model.trim="form.salesforce_id"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
        id="consortium_name"
        :invalid-feedback="handleError('form.consortium_name')"
        :state="isValid('form.consortium_name')"
        :label="translations.label_consortium_name">
          <b-form-input
          id="consortium_name"
          :disabled="loading"
          :placeholder="translations.label_consortium_name"
          v-model.trim="form.consortium_name"/>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
        id="consortium_id"
        :invalid-feedback="handleError('form.consortium_id')"
        :state="isValid('form.consortium_id')"
        :label="translations.label_consortium_id">
          <b-form-input
          id="consortium_id"
          :disabled="loading"
          :placeholder="translations.label_consortium_id"
          v-model.trim="form.consortium_id"/>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="my-4"/>

    <b-row v-for="(field, idx) in sharedFormFields" :key="idx">
      <b-col cols="4">
        <b-form-group
        :id="field.sf_program_id"
        :label="`${field.label} | ${translations.label_salesforce_program_id}`">
          <b-form-input
          :id="field.sf_program_id"
          :disabled="loading"
          :placeholder="translations.placeholder_salesforce_program_id"
          v-model.trim="form[field.sf_program_id]"/>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
        :id="field.technical_launch_date_id"
        :label="`${field.label} | ${translations.label_technical_launch_date}`">
          <b-form-datepicker
          :disabled="loading"
          v-model="form[field.technical_launch_date_id]"
          :date-format-options="displayDateFormat"/>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
        :id="field.marketing_launch_date_id"
        :label="`${field.label} | ${translations.label_marketing_launch_date}`">
          <b-form-datepicker
          :disabled="loading"
          v-model="form[field.marketing_launch_date_id]"
          :date-format-options="displayDateFormat"/>
        </b-form-group>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-button
      v-if="lineOfBusiness?.id"
      variant="danger"
      class="float-right"
      @click="handleDelete">
        {{ translations.btn_delete }}
      </b-button>
      <b-button
      variant="success"
      class="float-right"
      :disabled="formIsDisabled"
      @click="handleAddOrEdit">
        {{ translations.btn_save }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import translations from '@/translations';
import { parseResponseError } from '@/http/parsers/parse_response';
import { required } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import service from '@/services/eligibility-service';

export default {
  name: 'LineOfBusinessModalAddOrEdit',
  mixins: [ errorHandler ],
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    lineOfBusiness: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      translations: translations.client_eligibility.line_of_business,
      loading: false,
      opened: this.isVisible,
      displayDateFormat: { year: 'numeric', month: 'numeric', day: 'numeric' },
      form: {},
    };
  },
  validations() { return this.rules; },
  computed: {
    sharedFormFields() {
      return [
        {
          label: 'DPT',
          sf_program_id: 'dpt_salesforce_id',
          technical_launch_date_id: 'dpt_technical_launch_date',
          marketing_launch_date_id: 'dpt_marketing_launch_date',
        },
        {
          label: 'Bloom',
          sf_program_id: 'bloom_salesforce_id',
          technical_launch_date_id: 'bloom_technical_launch_date',
          marketing_launch_date_id: 'bloom_marketing_launch_date',
        },
        {
          label: 'Mind',
          sf_program_id: 'mind_salesforce_id',
          technical_launch_date_id: 'mind_technical_launch_date',
          marketing_launch_date_id: 'mind_marketing_launch_date',
        },
        {
          label: 'Move',
          sf_program_id: 'move_salesforce_id',
          technical_launch_date_id: 'move_technical_launch_date',
          marketing_launch_date_id: 'move_marketing_launch_date',
        },
        {
          label: 'DPT_GO',
          sf_program_id: 'dpt_go_salesforce_id',
          technical_launch_date_id: 'dpt_go_technical_launch_date',
          marketing_launch_date_id: 'dpt_go_marketing_launch_date',
        },
      ];
    },
    formIsDisabled() {
      return this.$v.form.$invalid || this.loading;
    },
    rules() {
      return {
        form: {
          line_of_business_name: { required },
        },
      };
    },
  },
  watch: {
    lineOfBusiness: {
      handler() {
        this.form = { ...this.lineOfBusiness };
      },
      deep: true,
    },
    isVisible: {
      handler() {
        this.opened = this.isVisible;
      },
    },
  },
  methods: {
    handleClose() {
      this.opened = false;
      this.$emit('update:isVisible', false);
    },
    async handleAddOrEdit() {
      try {
        this.loading = true;

        if (this.lineOfBusiness?.id) {
          await service.updateLineOfBusiness(this.clientId, this.lineOfBusiness.id, this.form);
        } else {
          await service.createLineOfBusiness(this.clientId, this.form);
        }
        this.$emit('refetch');
        this.handleClose();
        this.$noty.success(this.translations.success_save_line_of_business);
      } catch (err) {
        this.$noty.error(`${this.translations.error_save_line_of_business}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
    async handleDelete() {
      try {
        this.loading = true;
        await service.deleteLineOfBusiness(this.clientId, this.lineOfBusiness.id);
        this.$emit('refetch');
        this.handleClose();
        this.$noty.success(this.translations.success_delete_line_of_business);
      } catch (err) {
        this.$noty.error(`${this.translations.error_delete_line_of_business}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
