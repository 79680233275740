<template>
  <div>
    <!-- files table -->
    <b-table
      hover
      outlined
      :items="fileConfigs"
      :fields="fields"
      head-variant="light"
      style="border-width: 4px !important;">
      <template #cell(providerName)="row">
        {{ row.value }}
      </template>

      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="mr-4"
          variant="info"
          style="min-width: 0;"
          @click="openEditFileModal(row.index)">
          <feather type="edit-2" class="mr-2" />
          Edit
        </b-button>
        <span
          v-b-tooltip.hover
          :title="fileConfigs.length === 1 ? 'It is not possible to remove all of the config files.' : 'Remove file config'">
          <b-button
            variant="outline-danger"
            style="min-width: auto;"
            :disabled="isDeleteProviderDisabled"
            @click="removeConfig(row.item.id, row.index)">
            <feather type="trash" />
          </b-button>
        </span>
      </template>
    </b-table>

    <div class="text-right">
      <b-button @click="openAddFileModal">
        Add eligibility provider
      </b-button>
    </div>

    <!-- file modal -->
    <b-modal
      v-model="showConfigModal"
      size="xl"
      scrollable
      footer-bg-variant="light"
      title="Eligibility file config">
      <client-eligibility-file-config
        v-if="showConfigModal"
        v-model="currentConfig"
        :client="client"
        :multitenant-active="eligibility.multiTenant.active"
        :file-configs="fileConfigs"
        :payers="payers"
        @form-validation="onFormValidation"
        @quarantine-config-update="handleUpdateQuarantineConfigEvt"/>

      <template #modal-footer>
        <div class="w-100 d-flex">
          <b-button
            variant="outline-secondary"
            class="mr-4"
            @click="showConfigModal = false">
            Cancel
          </b-button>

          <div class="flex-1"></div>

          <b-button
            :disabled="!isCurrentConfigValid"
            variant="primary"
            @click="saveFileConfig">
            {{ isNewConfig ? 'Add' : 'Save' }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { isNullOrEmpty } from '@/helpers';
import { parseResponseError } from '@/http/parsers/parse_response';
import errorHandler from '@/mixins/errorHandler';
import ClientEligibilityFileConfig from './ClientEligibilityFileConfig.vue';

export default {
  name: 'ClientEligibilityFileConfigs',
  components: {
    ClientEligibilityFileConfig,
  },
  mixins: [ errorHandler ],
  props: {
    fileConfigs: { type: Array, default: () => [] },
    client: { type: Object, default: () => {} },
    eligibility: { type: Object, default: () => {} },
    payers: { type: Array, default: () => [] },
  },
  data() {
    return {
      fields: [
        { key: 'provider_name', label: 'Provider Name' },
        { key: 'folder', label: 'Files' },
        { key: 'actions', label: '', class: 'text-right' },
      ],
      fileModal: { id: 'file-modal', title: '', content: '' },
      currentConfig: {},
      showConfigModal: false,
      isRemovingConfig: false,
      isCurrentConfigValid: true,
      quarantineConfig: {
        isQuarantineEnabled: false,
        customConfigs: {},
        isValid: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
    }),
    isNewConfig() {
      return !this.currentConfig.id;
    },
    isDeleteProviderDisabled() {
      if (!this.eligibility.eligibilityFile.active) {
        return false;
      }
      if (this.fileConfigs.length > 1) {
        return false;
      }
      return true;
    },
  },
  methods: {
    async saveFileConfig() {
      this.isSaving = true;

      // validate quarantine config
      if (
        !this.quarantineConfig.isValid
        && this.isSftpSettingsEnabled
        && !isNullOrEmpty(this.quarantineConfig.filepathChanged)
        && !this.quarantineConfig.filepathChanged
      ) {
        this.$noty.error('Quarantine config is invalid. Please check the values and try again.');
        return;
      }

      let payload = {
        client_id: this.client.id,
        folder: this.currentConfig.folder,
        provider_name: this.currentConfig.provider_name,
        separator: this.currentConfig.separator,

        // generic fields
        column_number_first_name: this.parseNumberAPI(this.currentConfig.column_number_first_name),
        column_number_last_name: this.parseNumberAPI(this.currentConfig.column_number_last_name),
        column_number_member_id: this.parseNumberAPI(this.currentConfig.column_number_member_id),
        column_number_subscriber_id: this.parseNumberAPI(this.currentConfig.column_number_subscriber_id),
        column_number_dob: this.parseNumberAPI(this.currentConfig.column_number_dob),
        column_number_email: this.parseNumberAPI(this.currentConfig.column_number_email),
        column_number_gender: this.parseNumberAPI(this.currentConfig.column_number_gender),
        column_number_phone: this.parseNumberAPI(this.currentConfig.column_number_phone),
        column_number_address: this.parseNumberAPI(this.currentConfig.column_number_address),
        column_number_address2: this.parseNumberAPI(this.currentConfig.column_number_address2),
        column_number_city: this.parseNumberAPI(this.currentConfig.column_number_city),
        column_number_state: this.parseNumberAPI(this.currentConfig.column_number_state),
        column_number_country: this.parseNumberAPI(this.currentConfig.column_number_country),
        column_number_ssn: this.parseNumberAPI(this.currentConfig.column_number_ssn),
        column_number_group_policy_name: this.parseNumberAPI(this.currentConfig.column_number_group_policy_name),
        column_number_group_policy_id: this.parseNumberAPI(this.currentConfig.column_number_group_policy_id),
        column_number_zip_code: this.parseNumberAPI(this.currentConfig.column_number_zip_code),
        column_number_aso_group: this.parseNumberAPI(this.currentConfig.column_number_aso_group),
        column_number_ecosystem_external_id: this.parseNumberAPI(this.currentConfig.column_number_ecosystem_external_id),
        column_number_do_not_contact: this.parseNumberAPI(this.currentConfig.column_number_do_not_contact),
        column_number_employee_start_date: this.parseNumberAPI(this.currentConfig.column_number_employee_start_date),
        column_number_employee_term_date: this.parseNumberAPI(this.currentConfig.column_number_employee_term_date),
        column_number_health_coverage: this.parseNumberAPI(this.currentConfig.column_number_health_coverage),
        column_number_health_coverage_start_date: this.parseNumberAPI(this.currentConfig.column_number_health_coverage_start_date),
        column_number_health_coverage_end_date: this.parseNumberAPI(this.currentConfig.column_number_health_coverage_end_date),
        column_number_employer_business_unit: this.parseNumberAPI(this.currentConfig.column_number_employer_business_unit),
        column_number_plan_type: this.parseNumberAPI(this.currentConfig.column_number_plan_type),
        column_number_payer: this.parseNumberAPI(this.currentConfig.column_number_payer),
        column_number_service_type: this.parseNumberAPI(this.currentConfig.column_number_service_type),
        column_number_line_of_business: this.parseNumberAPI(this.currentConfig.column_number_line_of_business),

        external_ids: this.currentConfig.external_ids.map(({ label, column_number: columnNumber }) => ({
          label,
          column_number: this.parseNumberAPI(columnNumber),
        })),

        // programs
        gender: this.currentConfig.gender,
        payers: this.currentConfig.payers,
        service_type: this.currentConfig.service_type,
        line_of_businesses: this.currentConfig.line_of_businesses,
        physical_therapy: this.currentConfig.physical_therapy,
        on_call: this.currentConfig.on_call,
        academy: this.currentConfig.academy,
        dpt_go: this.currentConfig.dpt_go,
        move: this.currentConfig.move,
        bloom: this.currentConfig.bloom,
        mind: this.currentConfig.mind,
      };

      // dependent fields
      payload = {
        ...payload,
        dependent_mapping_option: this.currentConfig.dependent_mapping_option,
        dependent_mapping_column_number: this.parseNumberAPI(this.currentConfig.dependent_mapping_column_number),

        column_number_dependent_first_name: this.currentConfig.column_number_dependent_first_name,
        column_number_dependent_last_name: this.currentConfig.column_number_dependent_last_name,
        column_number_dependent_dob: this.currentConfig.column_number_dependent_dob,
        column_number_dependent_email: this.currentConfig.column_number_dependent_email,
        column_number_dependent_relationship: this.currentConfig.dependent_mapping_option === 2
          ? this.currentConfig.column_number_dependent_relationship_aggregate
          : this.currentConfig.column_number_dependent_relationship,
        column_number_dependent_gender: this.currentConfig.column_number_dependent_gender,
        column_number_dependent_phone: this.currentConfig.column_number_dependent_phone,
        column_number_dependent_address: this.currentConfig.column_number_dependent_address,
        column_number_dependent_address2: this.currentConfig.column_number_dependent_address2,
        column_number_dependent_city: this.currentConfig.column_number_dependent_city,
        column_number_dependent_state: this.currentConfig.column_number_dependent_state,
        column_number_dependent_country: this.currentConfig.column_number_dependent_country,
        column_number_dependent_zip_code: this.currentConfig.column_number_dependent_zip_code,
        column_number_dependent_member_id: this.currentConfig.column_number_dependent_member_id,
        column_number_dependent_ssn: this.currentConfig.column_number_dependent_ssn,
        column_number_physical_therapy: this.parseNumberAPI(this.currentConfig.column_number_physical_therapy),
        column_number_on_call: this.parseNumberAPI(this.currentConfig.column_number_on_call),
        column_number_academy: this.parseNumberAPI(this.currentConfig.column_number_academy),
        column_number_dpt_go: this.parseNumberAPI(this.currentConfig.column_number_dpt_go),
        column_number_move: this.parseNumberAPI(this.currentConfig.column_number_move),
        column_number_bloom: this.parseNumberAPI(this.currentConfig.column_number_bloom),
        column_number_mind: this.parseNumberAPI(this.currentConfig.column_number_mind),
        relationship: this.currentConfig.relationship,
        column_number_dependent_do_not_contact: this.currentConfig.column_number_dependent_do_not_contact,
        column_number_dependent_employee_start_date: this.currentConfig.column_number_dependent_employee_start_date,
        column_number_dependent_employee_term_date: this.currentConfig.column_number_dependent_employee_term_date,
        column_number_dependent_health_coverage: this.currentConfig.column_number_dependent_health_coverage,
        column_number_dependent_health_coverage_start_date: this.currentConfig.column_number_dependent_health_coverage_start_date,
        column_number_dependent_health_coverage_end_date: this.currentConfig.column_number_dependent_health_coverage_end_date,
        column_number_dependent_employer_business_unit: this.currentConfig.column_number_dependent_employer_business_unit,
        column_number_dependent_plan_type: this.currentConfig.column_number_dependent_plan_type,
        column_number_dependent_payer: this.currentConfig.column_number_dependent_payer,
      };

      try {
        if (this.isNewConfig) {
          const { data } = await axios.post(`v3/clients/${this.client.id}/eligibility/file-config`, payload);
          data.data.column_number_dependent_relationship_aggregate = data.data.column_number_dependent_relationship;
          this.$emit('config-add', data.data);
        } else {
          const { data } = await axios.put(`v3/clients/${this.client.id}/eligibility/file-config/${this.currentConfig.id}`, payload);
          data.data.column_number_dependent_relationship_aggregate = data.data.column_number_dependent_relationship;
          this.$emit('config-update', data.data);
        }
        this.$noty.success('Eligibility file saved!');

        if (this.isSftpSettingsEnabled) await this.updateQuarantineConfigs();
      } catch (error) {
        this.$noty.error(`Failed to save eligibility file: ${parseResponseError(error)}`);
      } finally {
        this.isSaving = false;
        this.showConfigModal = false;
      }
    },
    onFormValidation(valid) {
      this.isCurrentConfigValid = valid;
    },
    openAddFileModal() {
      this.currentConfig = this.getNewConfig();
      this.showConfigModal = true;
    },
    openEditFileModal(index) {
      this.currentConfig = JSON.parse(JSON.stringify(this.fileConfigs[index]));
      this.showConfigModal = true;
    },
    async removeConfig(configId, index) {
      const result = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this configuration?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        centered: true,
      });

      if (!result) return;

      this.isRemovingConfig = true;

      try {
        await axios.delete(`v3/clients/${this.client.id}/eligibility/file-config/${configId}`);

        this.$emit('config-remove', index);
        this.$noty.success('Config removed!');
      } catch (error) {
        this.$noty.error('Failed to remove config', error);
      } finally {
        this.isRemovingConfig = false;
      }
    },
    getNewConfig() {
      return {
        dependent_mapping_option: 0,
        folder: null,
        provider_name: null,
        separator: ','.charCodeAt(0),
        external_ids: [],
        relationship: [],
        gender: [],
        payers: [],
        service_type: [],
        line_of_businesses: [],
        physical_therapy: false,
        on_call: false,
        academy: false,
        dpt_go: false,
        move: false,
        bloom: false,
        mind: false,
      };
    },
    parseNumberAPI(value) {
      if (typeof value !== 'undefined' || Number(value) > 0) return Number(value);
      return undefined;
    },
    handleUpdateQuarantineConfigEvt(config) {
      // safeguard against null/undefined values from child component
      if (isNullOrEmpty(config.isValid)) {
        config.isValid = true;
      }

      this.quarantineConfig = config;
    },
    async updateQuarantineConfigs() {
      if (!isNullOrEmpty(this.quarantineConfig.filepathChanged) && this.quarantineConfig.filepathChanged) {
        this.$noty.warning(
          'Quarantine config update skipped. Filepath was changed. Please reload the page if you want to update the quarantine config.',
        );
        return;
      }

      const payload = {
        configs: this.quarantineConfig.mapping,
        filepath: this.currentConfig.folder,
      };

      if (this.quarantineConfig.isQuarantineEnabled !== null && this.quarantineConfig.isQuarantineEnabled !== undefined) {
        payload.client = {
          is_quarantine_enabled: this.quarantineConfig.isQuarantineEnabled,
        };
      }

      try {
        await this.$store.dispatch('Sftp/Eligibility/updateQuarantineConfigs', {
          onboarding_client_id: this.client.id,
          payload,
        });
      } catch (error) {
        this.$noty.error('Failed to update quarantine config', error);
      }
    },
  },
};
</script>
