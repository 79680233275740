<template>
  <div>
    <b-row class='mb-4'>
      <b-col cols='3'>
        <h5>Configuration</h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols='12'>
        <Configs
          :dryRun="true"
          :configs="indexedConfigs"/>
      </b-col>
    </b-row>
    <b-row class='mb-4 mt-4'>
      <b-col cols='3'>
        <h5>Report</h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols='12'>
        <Report
          :dryRun="true"
          :configs="indexedConfigs"
          :report="parsedReport"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Report from './Report.vue';
import Configs from './Configs.vue';

export default {
  name: 'Quarantine',
  components: {
    Report,
    Configs,
  },
  props: {
    preview: {
      type: Object,
    },
  },
  data() {
    return {
      indexedConfigs: {},
      parsedReport: {},
    };
  },
  beforeMount() {
    const { defaults, overrides } = this.preview.configuration.quarantine_configs;
    // eslint-disable-next-line no-restricted-syntax
    for (const c of Object.values(defaults)) {
      const override = overrides.find(o => o.uuid === c.uuid);
      if (override) {
        c.value = override.value;
      }
      this.indexedConfigs[c.name] = c;
    }

    this.parsedReport = {
      report: this.preview.quarantine_report,
      quarantined: this.preview.is_quarantined,
    };
  },
};
</script>

<style scoped lang="scss">

</style>
