<template>
  <div>
    <table class="table">
      <thead class="thead-light">
        <tr>
          <th scope="col" class="col-md-2">
            <b>Aso Group</b>
          </th>
          <th scope="col" class="col-md-2">
            <b>Payer Groups</b>
          </th>
          <th scope="col" class="col-md-2"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!asoPayerGroups.length">
          <td aria-colspan="2" colspan="2">
            No aso payer groups added yet
          </td>
        </tr>
        <template v-else>
          <tr v-for="(asoPayerGroup, asoPayerGroupIndex) in asoPayerGroups" :key="`info-${asoPayerGroupIndex}`">
            <td class="align-middle">
              {{ asoPayerGroup.aso_group.aso_group_name }}
            </td>
            <td class="align-middle">
              {{ payerGroupIDsLabel(asoPayerGroup) }}
            </td>
            <td class="align-middle">
              <b-button type="button" class="align-self-end w-100" variant="outline-secondary"
                :disabled="isRemovingOrEditingAsoPayerGroup" @click="openEditModal(asoPayerGroupIndex, asoPayerGroup)">
                <b-spinner v-if="isRemovingOrEditingAsoPayerGroup" label="Loading..." small />
                <feather v-else type="edit" />
              </b-button>
              <b-button type="button" class="align-self-end w-100 mt-2" variant="outline-danger"
                :disabled="isRemovingOrEditingAsoPayerGroup"
                @click="deleteAsoPayerGroup(asoPayerGroupIndex, asoPayerGroup)">
                <b-spinner v-if="isRemovingOrEditingAsoPayerGroup" label="Loading..." small />
                <feather v-else type="trash" />
              </b-button>
            </td>
          </tr>
        </template>
        <tr>
          <td class="align-middle">
            <multiselect v-model="asoGroup" placeholder="ASO Group" :options="asoGroups" track-by="id"
              label="aso_group_name" :multiple="false" :taggable="false" />
          </td>

          <td class="align-middle">
            <multiselect v-model="payerGroups" placeholder="Payer Group" :options="payerGroupOptions"
              track-by="unique_id" label="label" :multiple="true" :taggable="true" />
          </td>

          <td class="align-middle">
            <b-button type="button" class="align-self-end w-100" :disabled="isAddingAsoPayerGroup" variant="primary"
              @click="addAsoPayerGroup">
              <b-spinner v-if="isAddingAsoPayerGroup" label="Loading..." variant="light" small />
              <feather v-else type="plus" />
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Edit Modal -->
    <b-modal id="modal-edit-aso-payer-group" v-model="showEditModal" ok-title='Edit' size="lg" title="Edit Aso Payer"
      @ok="editAsoPayerGroup" @cancel="dissmissEditModal" no-close-on-esc no-close-on-backdrop hide-header-close>
      <b-col>
        <b-col cols="12">
          <b-form-group id="payer-group-ids" label-for="select-payer-group-ids" description="">
            <template #label>
              Payer Groups
            </template>
            <multiselect v-model="editPayerGroups" tag-placeholder="Add a new payer group" placeholder="Payer Group"
              :options="payerGroupOptions" track-by="unique_id" label="label" :multiple="true" :taggable="true"
              @tag="addEditPayerGroup($event)" />
          </b-form-group>
        </b-col>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  mixins: [],
  props: {
    client: {
      type: Object,
      default: () => { },
    },
    eligibility: {
      type: Object,
      default: () => { },
    },
    payers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      asoGroups: [],
      allPayers: [],
      asoPayerGroups: [],
      asoGroup: {},
      payerGroups: [],
      isAddingAsoPayerGroup: false,
      isRemovingOrEditingAsoPayerGroup: false,
      asoPayerGroupEdited: {},
      editPayerGroups: [],
      showEditModal: false,
    };
  },
  computed: {
    payerGroupOptions() {
      const result = [];
      this.allPayers.forEach(payer => {
        if (Array.isArray(payer.groups)) {
          payer.groups.forEach(group => {
            result.push({
              group_id: group.id,
              unique_id: `${group.id}${payer.id}`,
              label: `${group.group_id} (${payer.label})`,
            });
          });
        }
      });
      return result;
    },
  },
  validations() {
    return {};
  },
  watch: {
    eligibility: {
      async handler() {
        await Promise.all([
          this.fetchAsoGroups(),
          this.fetchAsoPayerGroups(),
        ]);
      },
      deep: true,
    },
    payers: {
      async handler() {
        await Promise.all([
          this.fetchPayers(),
          this.fetchAsoPayerGroups(),
        ]);
      },
      deep: true,
    },
  },
  async mounted() {
    await Promise.all([
      this.fetchAsoPayerGroups(),
      this.fetchAsoGroups(),
      this.fetchPayers(),
    ]);
  },
  methods: {
    payerGroupIDsLabel(payerGroup) {
      if (payerGroup) {
        const ids = payerGroup.payer_groups.map(group => (group.group_id));
        return ids.join(', ');
      }
      return '';
    },
    openEditModal(_, asoPayerGroup) {
      this.asoPayerGroupEdited = asoPayerGroup;
      this.editPayerGroups = asoPayerGroup.payer_groups.map(group => {
        const payer = this.allPayers.find(p => p.id === group.payer_id);
        return {
          group_id: group.id,
          unique_id: `${group.id}${payer.id}`,
          label: `${group.group_id} (${payer.label})`,
        };
      });
      this.showEditModal = true;
    },
    dissmissEditModal() {
      this.asoPayerGroupEdited = {};
      this.showEditModal = false;
    },
    addEditPayerGroup(payerGroup) {
      this.editPayerGroups.payer_groups.push(payerGroup);
      return payerGroup;
    },
    resetAdd() {
      this.asoGroup = {};
      this.payerGroups = [];
    },
    async addAsoPayerGroup() {
      this.isAddingAsoPayerGroup = true;
      try {
        await axios.post(`v1/clients/${this.client.id}/eligibility/aso-payer-groups`, {
          aso_group_id: this.asoGroup.id,
          payer_group_ids: this.payerGroups.map(payerGroup => payerGroup.group_id),
        });
        await this.fetchAsoPayerGroups();
        this.resetAdd();
        this.$noty.success('Aso Payer Group added!');
      } catch (error) {
        if (error.response.status === 409) {
          this.$noty.error(error.response.data.error.detail);
        } else {
          this.$noty.error('Failed to add aso payer group');
        }
      } finally {
        this.isAddingAsoPayerGroup = false;
      }
    },
    async editAsoPayerGroup() {
      this.isRemovingOrEditingAsoPayerGroup = true;
      try {
        const originalGroups = this.asoPayerGroupEdited.payer_groups;
        const editedGroups = this.editPayerGroups;

        // We add all the IDs that are present in the edited array but not in the original
        const addIds = editedGroups
          .filter(e => !originalGroups.find(o => o.group_id === e.group_id))
          .map(g => g.group_id);

        // We remove all the IDs that are present in the original array but not in the edited
        const removeIds = originalGroups
          .filter(o => !editedGroups.find(e => e.group_id === o.group_id))
          .map(g => g.id);

        await axios.put(`v1/clients/${this.client.id}/eligibility/aso-payer-groups/${this.asoPayerGroupEdited.aso_group.id}`, {
          add_payer_group_ids: addIds,
          remove_payer_group_ids: removeIds,
        });

        await this.fetchAsoPayerGroups();

        this.dissmissEditModal();

        this.$noty.success('Aso Payer Group edited!');
      } catch (error) {
        this.$noty.error('Failed to edit aso payer group', error);
      } finally {
        this.isRemovingOrEditingAsoPayerGroup = false;
      }
    },
    async deleteAsoPayerGroup(asoPayerGroupIndex, asoPayerGroup) {
      this.isRemovingOrEditingAsoPayerGroup = true;

      try {
        await axios.delete(`v1/clients/${this.client.id}/eligibility/aso-payer-groups/${asoPayerGroup.aso_group.id}`);

        await this.fetchAsoPayerGroups();

        this.$noty.success('Aso Payer Group deleted!');
      } catch (error) {
        this.$noty.error('Failed to delete aso payer group', error);
      } finally {
        this.isRemovingOrEditingAsoPayerGroup = false;
      }
    },
    async fetchAsoGroups() {
      try {
        const { data } = await axios.get(`v1/clients/${this.client.id}/multi-tenants/all`);
        this.asoGroups = data.data;
      } catch (error) {
        this.$noty.error('Failed to fetch all aso groups', error);
      }
    },
    async fetchPayers() {
      try {
        const { data } = await axios.get(`v1/clients/${this.client.id}/eligibility/payers/all`);
        this.allPayers = data.data;
      } catch (error) {
        this.$noty.error('Failed to fetch all payers', error);
      }
    },
    async fetchAsoPayerGroups() {
      try {
        const { data } = await axios.get(`v1/clients/${this.client.id}/eligibility/aso-payer-groups`);
        this.asoPayerGroups = data.data;
      } catch (error) {
        this.$noty.error('Failed to fetch all aso payer groups', error);
      }
    },
  },
};
</script>
