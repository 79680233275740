<template>
  <div v-if="loaded">
    <div v-if='canRender'>
      <b-row class='mb-4'>
        <b-col cols='3'>
          <h5>Configuration</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols='12'>
          <Configs
            @quarantine-config-update="handleQuarantineConfigUpdate"
            :client="client"
            :configs="configs"/>
        </b-col>
      </b-row>
      <b-row class='mb-4 mt-4'>
        <b-col cols='3'>
          <h5>Report</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols='12'>
          <Report
            v-if="canRenderReport"
            :configs='configs'
            :onboardingClientId="onboardingClientId"
            @quarantine-action-update="handleQuarantineActionUpdate"
            :report="report"/>
          <span v-else>No report available</span>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row class='mb-4'>
        <b-col>
          <span v-if="!filepath">Filepath is empty or invalid.</span>
          <span v-else>Filepath was changed. Please save the new config and reload the page to show the configurations.</span>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { isNullOrEmpty } from '@/helpers';
import Report from './Report.vue';
import Configs from './Configs.vue';

export default {
  name: 'Quarantine',
  components: {
    Report,
    Configs,
  },
  props: {
    onboardingClientId: {
      type: Number,
    },
    filepath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      filepathChanged: false,
      configs: {},
      client: {},
      report: {},
      latestEvent: {},
    };
  },
  computed: {
    canRender() {
      return !isNullOrEmpty(this.filepath) && !this.filepathChanged;
    },
    canRenderReport() {
      return !isNullOrEmpty(this.report);
    },
  },
  watch: {
    filepath: {
      handler() {
        // watch for filepath modifications. if the filepath changes we block the inputs and reject any attempts to save
        this.filepathChanged = (this.filepath !== this.originalFilepath);

        // if the filepath changes, we need to update the parent component to skip the quarantine config update
        if (this.filepathChanged) {
          this.latestEvent.filepathChanged = true;
          this.handleQuarantineConfigUpdate(this.latestEvent);
        } else if (this.latestEvent.filepathChanged && !this.filepathChanged) {
          // if the filepath changes back to the original value, we need to update the parent component to skip the quarantine config update
          this.latestEvent.filepathChanged = false;
          this.handleQuarantineConfigUpdate(this.latestEvent);
        }
      },
    },
  },
  async beforeMount() {
    await this.loadConfigurations();
    this.originalFilepath = this.filepath;
  },
  methods: {
    handleQuarantineConfigUpdate(val) {
      this.latestEvent = val;
      this.$emit('quarantine-config-update', val);
    },
    async handleQuarantineActionUpdate() {
      await this.loadConfigurations();
    },
    async loadConfigurations() {
      try {
        const { data } = await this.$store.dispatch('Sftp/Eligibility/getQuarantineConfigs', {
          onboarding_client_id: this.onboardingClientId,
          filepath: this.filepath,
        });

        this.client = data.client;
        data.configs.forEach(c => {
          this.configs[c.name] = {
            uuid: c.uuid,
            name: c.name,
            label: c.label,
            value: c.value,
            default_value: c.default_value,
            operator: c.operator,
            type: c.type,
            algorithm: c.algorithm,
            field_name: c.field_name,
          };
        });

        const { data: report } = await this.$store.dispatch('Sftp/Eligibility/getQuarantineReport', {
          onboarding_client_id: this.onboardingClientId,
          filepath: this.filepath,
        });

        this.report = report;
      } catch (err) {
        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.loaded = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">

</style>
