<template>
  <b-container class="mt-4 eligibility-settings-page">
    <b-spinner v-if="loading"/>
    <div v-else-if="client">
      <div class="d-flex align-items-center">
        <back-button/>
        <h5 class="ml-2 mb-0">
          Eligibility settings for client
          <b-link
            :to="`/onboarding/client/edit/${client.id}`">
            {{ client.display_name }}
          </b-link>
        </h5>
      </div>

      <!-- Eligibility file -->
      <b-card class="my-4" body-class="p-0">
        <template #header>
          <h5 class="float-left">Eligibility</h5>
          <b-button class="float-right" :disabled="isSaving" variant="primary" @click="trySaveEligibility">
            <b-spinner v-if="isSaving" label="Loading..." variant="light" small/>
            <span v-else>Save eligibility</span>
          </b-button>
        </template>
        <b-tabs pills card vertical>
            <b-tab title="Fingerprint" v-if="isAdmin || isManager">
              <template #title>
                Fingerprint
                <feather class="float-right" type="check-circle" v-if="tabStatus.fingerprint"></feather>
                <feather class="float-right" type="circle" v-else></feather>
              </template>

              <b-overlay :show="isFingerprintOverriddenByParent">
                <template #overlay>
                  <div class="text-center">
                    <feather type="lock"/>
                    <p>Fingerprint for this client is configured in the parent</p>
                  </div>
                </template>

                <b-alert show variant="warning">
                  Please note that this field is not editable, so once it is defined, this would always be the unique ID
                  for this client.
                </b-alert>

                <b-form-group
                  class="mx-0 px-0"
                  :invalid-feedback="handleError('eligibility.eligibilityFile.uniqueIdFields')"
                  :state="isValid('eligibility.eligibilityFile.uniqueIdFields')">
                  <multiselect
                    v-model="$v.eligibility.eligibilityFile.uniqueIdFields.$model"
                    class="multiselect"
                    :disabled="uniqueKeyFilled"
                    :options="uniqueKeyOptions"
                    track-by="key"
                    label="label"
                    :multiple="true"/>
                </b-form-group>

                <div class="text-secondary small">
                  <p>
                    Unique identifier for each member of the eligible population, to improve tracking throughout
                    each eligibility file revision and transversal identity across our systems.
                  </p>
                  <p>
                    It is an encrypted key based on the concatenation of environment, client ID and the selected
                    eligibility fields (at least 2) (eg: US:200:123456789:20000101 - using env:clientID:memberID:DOB)
                  </p>
                </div>

                <b-row v-if="!hasParent">
                  <b-col cols="12">
                    <label>
                      <toggle-button id="eligibility-configs-override_children_fingerprint_configs"
                                     :disabled="uniqueKeyFilled"
                                     v-model="eligibility.configs.override_children_fingerprint_configs"
                                     name="eligibility-configs-override_children_fingerprint_configs" sync/>
                      Use this configuration for child clients
                    </label>
                  </b-col>
                </b-row>
              </b-overlay>
              <hr class="mt-3 mb-3">
              <b-row>
                <b-col cols="12">
                  <label>
                    <toggle-button id="block_account_creation_by_fingerprint"
                                   v-model="eligibility.configs.block_account_creation_by_fingerprint"
                                   name="block_account_creation_by_fingerprint" sync/>
                    Block account creation by fingerprint
                  </label>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab title="Eligibility file">
              <template #title>
                Eligibility file
                <feather class="float-right" type="check-circle" v-if="tabStatus.eligibility_file"></feather>
                <feather class="float-right" type="circle" v-else></feather>
              </template>

              <b-tabs content-class="mt-3">
                <b-tab title="General" active>
                  <b-form-checkbox
                      id="use_eligibility_file"
                      v-model="eligibility.eligibilityFile.active"
                      name="use_eligibility_file">
                    Use Eligibility file
                  </b-form-checkbox>
                  <b-form-group
                    id="eligibility-sibling-client-ids-input"
                    class="float-left mt-3"
                    description="Insert here the clients you want to configure the eligibility check for. Start writing and click enter to add it."
                    invalid-feedback="Clients are required"
                    required
                    :state="isValid('eligibility.configs.sibling_client_ids')">
                      <template #label>
                        Check eligibility within this set of clients
                        <b-badge
                          variant="primary"
                          pill
                          class="float-right"
                          v-b-tooltip.hover
                          title="Add here only the extra clients where you want to check eligibility.">
                          <feather type="info"/>
                        </b-badge>
                      </template>
                      <multiselect
                          v-model="eligibility.configs.sibling_client_ids"
                          tag-placeholder="Add a new client"
                          placeholder="Clients"
                          :options="siblingClientOptions"
                          track-by="id"
                          label="label"
                          class="exception-multiselect"
                          :multiple="true"
                          :taggable="false"></multiselect>
                  </b-form-group>
                </b-tab>
                <b-tab title="Providers">
                  <client-eligibility-file-configs
                    :client="client"
                    :eligibility="eligibility"
                    :file-configs="fileConfigs"
                    :payers="payers"
                    @config-add="onFileConfigAdd"
                    @config-update="onFileConfigUpdate"
                    @config-remove="onFileConfigRemove"/>
                </b-tab>
              </b-tabs>
            </b-tab>

            <b-tab title="Sharecare">
              <template #title>
                Sharecare
                <feather class="float-right" type="check-circle" v-if="tabStatus.sharecare"></feather>
                <feather class="float-right" type="circle" v-else></feather>
              </template>

              <b-alert show variant="warning">
                When Sharecare is enabled, only Sharecare will be used
                for the eligibility check for this client.
                All other settings (including the whitelist will be ignored).
              </b-alert>

              <div class="mt-3">
                <b-form-checkbox
                    id="use_sharecare"
                    v-model="eligibility.configs.sharecare"
                    name="use_sharecare">
                  Use Sharecare
                </b-form-checkbox>
              </div>
            </b-tab>

            <b-tab title="Solera">
              <template #title>
                Solera
                <feather class="float-right" type="check-circle" v-if="tabStatus.solera"></feather>
                <feather class="float-right" type="circle" v-else></feather>
              </template>

              <b-alert show variant="warning">
                When Solera is enabled, only Solera will be used
                for the eligibility check for this client.
                All other settings (including the whitelist will be ignored).
              </b-alert>

              <div class="mt-3">
                <b-form-checkbox
                  id="use_solera"
                  v-model="eligibility.configs.solera"
                  name="use_solera">
                  Use Solera
                </b-form-checkbox>
              </div>
            </b-tab>

            <b-tab title="Availity">
              <template #title>
                Availity
                <feather class="float-right" type="check-circle" v-if="tabStatus.availity"></feather>
                <feather class="float-right" type="circle" v-else></feather>
              </template>
              <div class="mt-3">
                <b-form-checkbox
                    id="use_availity"
                    v-model="eligibility.configs.availity"
                    name="use_availity">
                  Use Availity
                </b-form-checkbox>
              </div>

              <b-form-group
                label="Service Type"
                class="mt-4 mx-0 px-0">
                <b-form-input
                  id="input-availity-service-type"
                  v-model="eligibility.configs.service_type_code"
                  type="text"/>
                <slot name="description">
                  <small>
                    Service Type to check eligibility
                    <a
                      href="https://x12.org/codes/service-type-codes"
                      target="_blank"
                      rel="noopener noreferrer">See
                      available
                      service types here.</a>
                  </small>
                </slot>
              </b-form-group>
            </b-tab>

            <b-tab title="Waystar">
              <template #title>
                Waystar
                <feather class="float-right" type="check-circle" v-if="tabStatus.waystar"></feather>
                <feather class="float-right" type="circle" v-else></feather>
              </template>
              <div class="mt-3">
                <b-form-checkbox
                    id="use_waystar"
                    v-model="eligibility.configs.waystar"
                    name="use_waystar">
                  Use Waystar
                </b-form-checkbox>
              </div>

              <b-form-group
                label="Service Type"
                class="mt-4 mx-0 px-0">
                <b-form-input
                  id="input-waystar-service-type"
                  v-model="eligibility.configs.waystar_service_type_code"
                  type="text"/>
                <slot name="description">
                  <small>
                    Service Type to check eligibility
                    <a
                      href="https://x12.org/codes/service-type-codes"
                      target="_blank"
                      rel="noopener noreferrer">
                      See available service types here.
                    </a>
                  </small>
                </slot>
              </b-form-group>
            </b-tab>

            <b-tab v-if="$isSudo" title="Change Healthcare" class="pr-3">
              <template #title>
                Change Healthcare
                <feather class="float-right" type="check-circle" v-if="tabStatus.change_healthcare"></feather>
                <feather class="float-right" type="circle" v-else></feather>
              </template>
              <div class="mt-3">
                <b-form-checkbox
                    id="use_change_healthcare"
                    v-model="eligibility.configs.change_healthcare"
                    name="use_change_healthcare">
                  Use Change Healthcare
                </b-form-checkbox>
              </div>

              <b-form-group
                label="Service Type"
                class="mt-4 mx-0 px-0">
                <b-form-input
                  id="input-change_healthcare-service-type"
                  v-model="eligibility.configs.change_healthcare_service_type_code"
                  type="text"/>
                <slot name="description">
                  <small>
                    Service Type to check eligibility
                    <a
                      href="https://x12.org/codes/service-type-codes"
                      target="_blank"
                      rel="noopener noreferrer">
                      See available service types here.
                    </a>
                  </small>
                </slot>
              </b-form-group>
            </b-tab>

          <b-tab title="MGB" class="pr-3">
            <template #title>
              MGB
              <feather class="float-right" type="check-circle" v-if="tabStatus.mgb"></feather>
              <feather class="float-right" type="circle" v-else></feather>
            </template>
            <div class="mt-3">
              <b-alert show variant="warning">
                MGB is currently only available for Bloom.
              </b-alert>

              <b-form-checkbox
                id="use_mgb"
                v-model="eligibility.configs.mgb"
                name="use_mgb">
                Use MGB
              </b-form-checkbox>
            </div>

            <b-form-group
              label="Service Type"
              class="mt-4 mx-0 px-0">
              <b-form-input
                id="input-mgb-service-type"
                v-model="eligibility.configs.mgb_service_type_code"
                type="text"/>
              <slot name="description">
                <small>
                  Service Type to check eligibility
                  <a
                    href="https://x12.org/codes/service-type-codes"
                    target="_blank"
                    rel="noopener noreferrer">
                    See available service types here.
                  </a>
                </small>
              </slot>
            </b-form-group>
          </b-tab>

            <b-tab title="Whitelist Sword Emails" v-if="$isSudo">
              <template #title>
                Whitelist Sword
                <feather class="float-right" type="check-circle" v-if="tabStatus.whitelist_sword_emails"></feather>
                <feather class="float-right" type="circle" v-else></feather>
              </template>

              <b-alert show variant="warning">
                When Whitelist Sword Emails is enabled, anyone with a @swordhealth,
                @hibloom, or @swordcare can pass the eligibility check for this client.
              </b-alert>

              <div class="mt-3">
                <b-form-checkbox
                    id="use_sword_emails"
                    v-model="eligibility.configs.whitelist_sword_emails"
                    name="use_sword_emails">
                  Enable Whitelist Sword Emails
                </b-form-checkbox>
              </div>
            </b-tab>
          </b-tabs>
      </b-card>

      <b-modal ref="rte-edit-warning-modal" hide-footer title="RTE Edit Warning">
        <div class="d-block text-center">
          <p>Changing RTE provider will delete the existing Payers and you’ll need to add the payers again. Are you sure you want to continue?</p>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click="onRTEditWarningModalOKBtn">Yes</b-button>
        <b-button class="mt-3" variant="outline-danger" block @click="hideRTEEditWarningModal">No</b-button>
      </b-modal>

      <hr class="my-4"/>

      <client-eligibility-multi-tenants
        :client="client"
        :eligibility="eligibility"
        :payers="payers"
        :aso_form="aso_form"/>

      <hr class="my-4"/>

      <client-eligibility-payers
        :client="client"
        :payers="payers"
        :file-configs="fileConfigs"
        :eligibility="eligibility"
        @payer-add="onPayerAdd"
        @payer-remove="onPayerRemove"
        @payer-updated="onPayerUpdated"/>

      <hr class="my-4"/>

      <client-eligibility-line-of-business :client-id="client.id"/>
    </div>
  </b-container>
</template>

<script>
import * as R from 'ramda';
import { mapGetters } from 'vuex';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import errorHandler from '@/mixins/errorHandler';
import BackButton from '@/components/BackButton.vue';
import ClientEligibilityFileConfigs from '@/components/ClientEligibility/ClientEligibilityFileConfigs.vue';
import ClientEligibilityPayers from '@/components/ClientEligibility/ClientEligibilityPayers.vue';
import ClientEligibilityMultiTenants from '@/components/ClientEligibility/ClientEligibilityMultiTenants.vue';
import ClientEligibilityLineOfBusiness from '@/components/ClientEligibility/ClientEligibilityLineOfBusiness/index.vue';

export default {
  name: 'ClientEligibilitySettings',
  components: {
    BackButton,
    Multiselect,
    ClientEligibilityFileConfigs,
    ClientEligibilityPayers,
    ClientEligibilityMultiTenants,
    ClientEligibilityLineOfBusiness,
  },
  mixins: [ errorHandler ],
  data() {
    return {
      eligibilitySettings: {},
      payers: [],
      fileConfigs: [],
      loading: false,
      client: null,
      parent: null,
      isSaving: false,
      siblingClientOptions: [],
      eligibility: {
        multiTenant: {
          active: false,
          file: null,
          fileName: '',
          table: {
            fields: [
              { key: 'general', label: 'General' },
              { key: 'dpt', label: 'DPT' },
              { key: 'bloom', label: 'Bloom' },
              { key: 'mind', label: 'Mind' },
              { key: 'move', label: 'Move' },
              { key: 'dpt_go', label: 'DPT_GO' },
            ],
            items: [],
          },
        },
        configs: {
          availity: false,
          waystar: false,
          change_healthcare: false,
          sharecare: false,
          solera: false,
          mgb: false,
          service_type_code: 30,
          waystar_service_type_code: 30,
          change_healthcare_service_type_code: 30,
          mgb_service_type_code: 30,
          providerName: '--',
          whitelist_sword_emails: false,
          sibling_client_ids: [],
          block_account_creation_by_fingerprint: false,
          override_children_fingerprint_configs: false,
        },
        previousConfigs: {
          availity: false,
          waystar: false,
          change_healthcare: false,
          sharecare: false,
          solera: false,
          mgb: false,
          service_type_code: 30,
          waystar_service_type_code: 30,
          change_healthcare_service_type_code: 30,
          mgb_service_type_code: 30,
          providerName: '--',
          whitelist_sword_emails: false,
          sibling_client_ids: [],
          block_account_creation_by_fingerprint: false,
        },
        eligibilityFile: {
          active: false,
          uniqueIdFields: [],
        },
      },
      initialChildConfigs: [],
      uniqueKeyOptions: [
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        { key: 'dob', label: 'Date of Birth' },
        { key: 'member_id', label: 'Member ID' },
        { key: 'gender', label: 'Gender' },
        { key: 'relationship', label: 'Relationship' },
        { key: 'ssn', label: 'SSN' },
      ],
      aso_form: {
        title: 'New ASO group',
        data: {
          aso_group: '',
          aso_group_name: '',
          start_date: null,
          consortium_id: '',
          consortium_name: '',
          aso_salesforce_id: '',
          dpt_salesforce_id: '',
          bloom_salesforce_id: '',
          mind_salesforce_id: '',
          move_salesforce_id: '',
          dpt_go_salesforce_id: '',
          dpt_technical_launch_date: '',
          bloom_technical_launch_date: '',
          mind_technical_launch_date: '',
          move_technical_launch_date: '',
          dpt_go_technical_launch_date: '',
          dpt_marketing_launch_date: '',
          bloom_marketing_launch_date: '',
          mind_marketing_launch_date: '',
          move_marketing_launch_date: '',
          dpt_go_marketing_launch_date: '',
        },
      },
      uniqueKeyFilled: true,
      isAdmin: false,
      isManager: false,
    };
  },
  computed: {
    ...mapGetters({
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
    }),
    tabStatus() {
      return {
        fingerprint: this.eligibility.eligibilityFile.uniqueIdFields.length > 0,
        eligibility_file: this.eligibility.eligibilityFile.active,
        sharecare: this.eligibility.configs.sharecare,
        solera: this.eligibility.configs.solera,
        availity: this.eligibility.configs.availity,
        waystar: this.eligibility.configs.waystar,
        change_healthcare: this.eligibility.configs.change_healthcare,
        mgb: this.eligibility.configs.mgb,
        whitelist_sword_emails: this.eligibility.configs.whitelist_sword_emails,
      };
    },
    hasParent() {
      return this.client.parent_id !== null;
    },
    isFingerprintOverriddenByParent() {
      if (!this.parent) {
        return false;
      }
      return this.parent.eligibility_settings?.configs?.data?.override_children_fingerprint_configs;
    },
    rules() {
      return {
        eligibility: {
          eligibilityFile: {
            uniqueIdFields: {
              minLength: v => !(v.length > 0 && v.length < 1) || this.uniqueKeyFilled,
            },
          },
        },
      };
    },
  },
  validations() {
    return this.rules;
  },
  watch: {
    clientId() {
      this.loadClientEligibilitySettings();
    },
  },
  async mounted() {
    this.loading = true;

    try {
      const clients = await this.$store.dispatch('Core/fetchClients');

      clients.forEach(client => {
        if (Number(client.id) !== Number(this.$route.params.clientId)) {
          this.siblingClientOptions.push({ id: client.id, label: `${client.display_name} (id:${client.id})` });
        }
      });

      this.client = clients.find(c => Number(c.id) === Number(this.$route.params.clientId));
      if (this.client.parent_id) {
        this.parent = clients.find(c => Number(c.id) === Number(this.client.parent_id));
        await this.loadParentEligibilitySettings();
      }
      await this.loadClientEligibilitySettings();

      this.isManager = this.$store.getters['User/isManager'];
      this.isAdmin = this.$store.getters['User/isAdmin'];
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    showRTEEditWarningModal() {
      this.$refs['rte-edit-warning-modal'].show();
    },
    hideRTEEditWarningModal() {
      this.$refs['rte-edit-warning-modal'].hide();
    },
    async loadParentEligibilitySettings() {
      if (!this.parent) {
        return;
      }
      const { data } = await axios.get(`v1/clients/${this.parent.id}/eligibility/settings`);
      this.parent.eligibility_settings = data;
    },
    async loadClientEligibilitySettings() {
      const { data } = await axios.get(`v1/clients/${this.client.id}/eligibility/settings`);

      // Only fetch eligibility metadata if the feature is enabled
      let providerMetadata = [];
      if (this.isSftpSettingsEnabled) {
        try {
          providerMetadata = await axios.get(`v1/sftp/eligibility/${this.$route.params.clientId}/metadata`)
            .then(R.prop('data'));
        } catch (e) {
          console.error(e);
        }
      }

      this.eligibilitySettings = data;
      this.payers = this.eligibilitySettings.payers.data || [];

      if (this.eligibilitySettings.multiProvider.data) {
        for (let i = 0; i < this.eligibilitySettings.multiProvider.data.length; i++) {
          this.eligibilitySettings.multiProvider.data[i]
            .column_number_dependent_relationship_aggregate = this.eligibilitySettings.multiProvider.data[i]
              .column_number_dependent_relationship;
        }
      }
      this.fileConfigs = this.eligibilitySettings.multiProvider.data || [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [ k, v ] of Object.entries(this.fileConfigs)) {
        const provMeta = providerMetadata.find(p => p.filepath === v.folder);
        this.fileConfigs[k] = R.mergeDeepLeft(provMeta, this.fileConfigs[k]);
      }
      this.getClientEligibility();
    },
    checkNested(obj, level, ...rest) {
      if (obj === undefined) return false;
      if (!rest.length && level in obj) return true;

      return this.checkNested(obj[level], ...rest);
    },
    async saveEligibilityChecks() {
      const eligibilityParams = Object.assign(this.eligibility.configs, {
        availity: this.eligibility.configs.availity,
        waystar: this.eligibility.configs.waystar,
        change_healthcare: this.eligibility.configs.change_healthcare,
        sharecare: this.eligibility.configs.sharecare,
        solera: this.eligibility.configs.solera,
        mgb: this.eligibility.configs.mgb,
        service_type_code: String(this.eligibility.configs.service_type_code),
        waystar_service_type_code: String(this.eligibility.configs.waystar_service_type_code ?? '30'),
        change_healthcare_service_type_code: String(this.eligibility.configs.change_healthcare_service_type_code ?? '30'),
        mgb_service_type_code: String(this.eligibility.configs.mgb_service_type_code ?? '30'),
        eligibility_file: this.eligibility.eligibilityFile.active,
        eligibility_file_unique_id_fields: this.eligibility.eligibilityFile.uniqueIdFields.map(v => v.key),
        whitelist: true,
        use_multi_tenant_eligibility: this.eligibility.multiTenant.active,
        whitelist_sword_emails: this.eligibility.configs.whitelist_sword_emails,
        sibling_client_ids: this.eligibility.configs.sibling_client_ids.map(client => Number(client.id)),
        block_account_creation_by_fingerprint: !!this.eligibility.configs.block_account_creation_by_fingerprint,
        override_children_fingerprint_configs: !!this.eligibility.configs.override_children_fingerprint_configs,
      });

      const { data } = await axios.post(`v1/clients/${this.client.id}/eligibility/configs`, eligibilityParams);

      return data.data;
    },
    reloadEligibilityChecks(data) {
      if (!this.eligibilitySettings.configs) this.eligibilitySettings.configs = {};

      this.eligibilitySettings.configs.data = data;

      this.getClientEligibility();
    },
    async onRTEditWarningModalOKBtn(bvModalEvent) {
      bvModalEvent.preventDefault(); // manually close after success

      await this.saveEligibility();
      await this.loadClientEligibilitySettings();

      this.hideRTEEditWarningModal();
    },
    async trySaveEligibility() {
      if (this.eligibility.configs.availity !== this.eligibility.previousConfigs.availity
       || this.eligibility.configs.waystar !== this.eligibility.previousConfigs.waystar
       || this.eligibility.configs.change_healthcare !== this.eligibility.previousConfigs.change_healthcare
       || this.eligibility.configs.mgb !== this.eligibility.previousConfigs.mgb
      ) {
        this.showRTEEditWarningModal();
      } else {
        await this.saveEligibility();
      }
    },
    async saveEligibility() {
      if (this.$v.eligibility.eligibilityFile.uniqueIdFields.$invalid) {
        this.$noty.error('Unable to save eligibility settings. Please check unique key');
        return;
      }

      const {
        availity,
        waystar,
        change_healthcare: changeHealthcare,
        mgb,
      } = this.eligibility.configs;

      if ([ availity, waystar, changeHealthcare, mgb ].filter(provider => provider).length > 1) {
        this.$noty.error('Only one active provider allowed at a time. Please enable just one of: Availity, Waystar, Change Healthcare or MGB');
        return;
      }

      this.isSaving = true;

      try {
        const data = await this.saveEligibilityChecks();

        this.reloadEligibilityChecks(data);
        this.$noty.success('Settings saved');
      } catch (errorcatch) {
        let msgError = '';

        if (this.checkNested(errorcatch, 'response', 'data', 'error', 'labels', 'error')) {
          msgError = JSON.stringify(errorcatch.response.data.error.labels.error);
        } else {
          msgError = (errorcatch.response.data ? JSON.stringify(errorcatch.response.data) : '');
        }
        this.$noty.error(`Unable to save eligibility settings: \n${msgError}`);
      } finally {
        this.isSaving = false;
      }
    },
    getClientEligibility() {
      try {
        const configs = this.eligibilitySettings.configs || {};
        const eligibilityConfigs = configs.data || {
          availity: false,
          waystar: false,
          change_healthcare: false,
          sharecare: false,
          solera: false,
          mgb: false,
          service_type_code: null,
          waystar_service_type_code: '30',
          change_healthcare_service_type_code: '30',
          mgb_service_type_code: '30',
          block_account_creation_by_fingerprint: false,
          override_children_fingerprint_configs: false,
        };

        this.eligibility.configs = eligibilityConfigs;
        this.eligibility.previousConfigs = { ...eligibilityConfigs }; // clone
        this.eligibility.multiTenant.active = eligibilityConfigs.use_multi_tenant_eligibility || false;
        this.eligibility.eligibilityFile.active = eligibilityConfigs.eligibility_file || false;

        const uniqueIdFieldsOptions = this.uniqueKeyOptions;

        this.eligibility.eligibilityFile.uniqueIdFields = (eligibilityConfigs.eligibility_file_unique_id_fields || [])
          .map(v => uniqueIdFieldsOptions.find(opt => opt.key === v) || { key: v, label: `UNKNOWN LABEL! - ${v}` });
        this.uniqueKeyFilled = this.eligibility.eligibilityFile.uniqueIdFields.length > 0;

        this.eligibility.configs.sibling_client_ids = (eligibilityConfigs.sibling_client_ids || [])
          .map(id => this.siblingClientOptions.find(option => Number(option.id) === Number(id)));
      } catch (error) {
        if (error.response && error.response.status === 404) {
          return;
        }
        this.$noty.error('Failed getting client eligibility', error);
      }
    },
    onPayerAdd(payer) {
      this.payers.push(payer);
    },
    onPayerRemove(index) {
      this.payers.splice(index, 1);
    },
    onPayerUpdated(payer) {
      const idx = this.payers.findIndex(p => p.id === payer.id);
      this.$set(this.payers, idx, payer);
    },
    onFileConfigAdd(fileConfig) {
      this.fileConfigs.push(fileConfig);
    },
    onFileConfigUpdate(fileConfig) {
      const idx = this.fileConfigs.findIndex(f => f.id === fileConfig.id);
      this.$set(this.fileConfigs, idx, fileConfig);
    },
    onFileConfigRemove(index) {
      this.fileConfigs.splice(index, 1);
    },
  },

};
</script>

<style scoped lang="scss">
.unique-key-tooltip::v-deep {
  .tooltip-inner {
    max-width: 450px;
  }
}
.eligibility-settings-page::v-deep {
  .nav-pills {
    .nav-item {
      width: 200px;
    }
  }
}
</style>
