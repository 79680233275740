<template>
  <div v-if="loaded">
    <b-row class='mt-4'>
      <b-col>
        <hr />
      </b-col>
    </b-row>
    <b-row class='mb-4'>
      <b-col>
        <h5>File Preview</h5>
        <small>Generate file to preview how configurations and mapping are applied</small>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols='3'>
        <label>Choose a file</label>
      </b-col>
      <b-col cols='9'>
        <b-form-select v-model="filenameToPreview" :options="files"></b-form-select>
      </b-col>
    </b-row>
    <b-row class='mt-1 mb-2'>
      <b-col class='d-flex justify-content-end'>
        <b-button :disabled="isPreviewDisabled()" @click='previewFileIngestion()'>Preview File Ingestion</b-button>
      </b-col>
    </b-row>
    <div>
      <b-row>
        <b-col>
          <h5>Last execution details</h5>
        </b-col>
      </b-row>
      <div v-if="lastPreviewResult && lastPreviewResult.is_completed">
        <EligibilityInfoDisplay
          v-if="lastPreviewResult.processor_engine === 'eligibility'"
          :result="lastPreviewResult"
          :payers="payers"/>
        <GenericImporterInfoDisplay
          v-if="lastPreviewResult.processor_engine === 'generic'"
          :result="lastPreviewResult"/>
      </div>
      <span v-else-if="(lastPreviewResult && !lastPreviewResult.is_completed) || inProgress">Execution in progress...</span>
      <span v-else>No previous execution found</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import EligibilityInfoDisplay from './EligibilityInfoDisplay.vue';
import GenericImporterInfoDisplay from './GenericImporterInfoDisplay.vue';

export default {
  name: 'ClientFilePreview',
  components: {
    EligibilityInfoDisplay,
    GenericImporterInfoDisplay,
  },
  props: {
    config: {
      type: Object,
      default: () => { },
    },
    quarantineConfig: {
      type: Object,
      default: () => { },
    },
    processorEngine: {
      type: String,
      default: 'eligibility',
    },
    payers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      lastPreviewResult: 'Sftp/DryRun/getLastPreviewResult',
    }),
  },
  data() {
    return {
      files: [ { value: '', text: 'Choose file', disabled: true } ],
      filenameToPreview: '',
      loaded: false,
      inProgress: false,
      submitting: false,
    };
  },
  async beforeMount() {
    this.$store.commit('Sftp/DryRun/setLastPreviewResult', null);
    this.$store.commit('Sftp/DryRun/setOnboardingClientId', this.$route.params.clientId);
    this.$store.commit('Sftp/DryRun/setFilepath', this.config.folder);
    await this.loadProviderFiles();
    await this.$store.dispatch('Sftp/DryRun/getLastFilePreview');
    this.loaded = true;
  },
  watch: {
    'config.folder': {
      handler() {
        this.$store.commit('Sftp/DryRun/setFilepath', this.config.folder);
        this.$store.commit('Sftp/DryRun/setLastPreviewResult', null);
        return this.loadProviderFiles();
      },
    },
  },
  beforeDestroy() {
    this.$store.commit('Sftp/DryRun/setLastPreviewResult', null);
    this.$store.commit('Sftp/DryRun/setOnboardingClientId', null);
    this.$store.commit('Sftp/DryRun/setFilepath', null);
  },
  methods: {
    async loadProviderFiles() {
      try {
        const { data: files } = await this.$store.dispatch('Sftp/Storage/getFilesByFilepath',
          {
            filepath: this.config.folder,
          });

        this.files = [];
        files.forEach(f => {
          this.files.push({
            value: f.filename,
            text: `${f.filename} - ${this.normalizeUpdatedAt(f.updated_at)}`,
          });
        });
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async previewFileIngestion() {
      if (this.filenameToPreview === '') {
        this.$noty.error('Select a valid file to preview');
        return;
      }
      this.submitting = true;

      try {
        // this is working for eligibility but most likely will not work for other processor engines without changes
        switch (this.processorEngine) {
          case 'eligibility':
            await this.$store.dispatch('Sftp/DryRun/dryRunFileIngestion', {
              data: {
                onboarding_client_id: this.$route.params.clientId,
                filepath: this.config.folder,
                filename: this.filenameToPreview,
                processor_engine: this.processorEngine,
                configuration: {
                  mapping: this.config,
                  quarantine_configs: {
                    defaults: this.quarantineConfig.configs,
                    overrides: this.quarantineConfig.mapping,
                  },
                },
              },
            });
            break;
          case 'generic':
            await this.$store.dispatch('Sftp/DryRun/dryRunFileIngestion', {
              data: {
                onboarding_client_id: this.$route.params.clientId,
                filepath: this.config.folder,
                filename: this.filenameToPreview,
                processor_engine: this.processorEngine,
                configuration: {
                  mapping: this.config.mapping,
                  normalizers: this.config.normalizers,
                  provider_id: this.config.provider_id,
                  delimiter: String.fromCharCode(this.config.delimiter),
                  external_unique_id_column_number: this.config.external_unique_id_column_number,
                  file_type_id: this.config.file_type_id,
                },
              },
            });
            break;
          default:
            console.error('Unknown processor engine');
            this.$noty.error('Internal error. Processor engine is invalid');
            break;
        }

        this.inProgress = true;
        this.$store.commit('Sftp/DryRun/setLastPreviewResult', null);
        this.$noty.success('File preview successfully submitted');
      } catch (err) {
        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.submitting = false;
      }
    },
    normalizeUpdatedAt(updatedAt) {
      return `last modified at ${moment(updatedAt).format('YYYY-MM-DD HH:mm')}`;
    },
    isPreviewDisabled() {
      return this.submitting || this.filenameToPreview === '' || (this.lastPreviewResult && !this.lastPreviewResult.is_completed);
    },
  },
};

</script>
