<template>
  <div class="metadata-container">
    <div class="metadata-row">
      <div class="metadata-label">Execution ID:</div>
      <div class="metadata-value">{{ result.uuid }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Success:</div>
      <div class="metadata-value"
           :class="{ 'text-success': result.success, 'text-danger': !result.success }">
        {{ result.success ? 'Yes' : 'No' }}
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">File Name:</div>
      <div class="metadata-value">{{ result.filename }}</div>
    </div>
    <div class="metadata-row" v-if="result.metadata">
      <div class="metadata-label">File Size:</div>
      <div class="metadata-value">{{ result.metadata.file_size }}</div>
    </div>
    <div class="metadata-row" v-if="result.metadata">
      <div class="metadata-label">Valid Rows:</div>
      <div class="metadata-value">{{ result.metadata.ingested_rows }}</div>
    </div>
    <div class="metadata-row" v-if="result.metadata">
      <div class="metadata-label">Invalid Rows:</div>
      <div class="metadata-value">{{ result.metadata.invalid_rows }}</div>
    </div>
    <div class="metadata-row" v-if="result.metadata">
      <div class="metadata-label">Rows with Warnings:</div>
      <div class="metadata-value">{{ result.metadata.ingested_rows_with_warnings }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Unique ID Combination:</div>
      <div class="metadata-value">{{ result.unique_id_combination }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">File delimiter:</div>
      <div class="metadata-value">{{ String.fromCharCode(result.configuration.mapping.separator) }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">File Mapping:</div>
      <div class="metadata-value">
        <div class="table-container" v-if="mapping && mapping.items.length">
          <b-table class="table-bordered" striped hover sticky-header :items="mapping.items" :fields="mapping.fields">
            <template #cell(line)="data">
              {{ data.column_name }}
            </template>
            <template #cell(message)="data">
              {{ data.column_number }}
            </template>
          </b-table>
        </div>
        <span v-else>No errors</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Relationship mapping:</div>
      <div class="metadata-value">
        <div class="table-container" v-if="relationship_mapping && relationship_mapping.items.length">
          <b-table class="table-bordered" striped hover sticky-header :items="relationship_mapping.items" :fields="relationship_mapping.fields">
            <template #cell(line)="data">
              {{ data.key }}
            </template>
            <template #cell(message)="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>
        <span v-else>No mapping defined</span>
      </div>
    </div>
    <!-- TODO: test this -->
    <div class="metadata-row">
      <div class="metadata-label">Gender mapping:</div>
      <div class="metadata-value">
        <div class="table-container" v-if="gender_mapping && gender_mapping.items.length">
          <b-table class="table-bordered" striped hover fixed sticky-header :items="gender_mapping.items"
            :fields="gender_mapping.fields">
            <template #cell(line)="data">
              {{ data.key }}
            </template>
            <template #cell(message)="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>
        <span v-else>No mapping defined</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Payers mapping:</div>
      <div class="metadata-value">
        <div class="table-container" v-if="payers_mapping && payers_mapping.items.length">
          <b-table class="table-bordered" striped hover fixed sticky-header :items="payers_mapping.items"
            :fields="payers_mapping.fields">
            <template #cell(line)="data">
              {{ data.key }}
            </template>
            <template #cell(message)="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>
        <span v-else>No mapping defined</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Service type mapping:</div>
      <div class="metadata-value">
        <div class="table-container" v-if="service_type_mapping && service_type_mapping.items.length">
          <b-table class="table-bordered" striped hover fixed sticky-header :items="service_type_mapping.items"
            :fields="service_type_mapping.fields">
            <template #cell(line)="data">
              {{ data.key }}
            </template>
            <template #cell(message)="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>
        <span v-else>No mapping defined</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">
        Errors
        <div>
          <small>First 100 records</small>
        </div>
      </div>
      <div class="metadata-value">
        <div class="table-container" v-if="result.errors && result.errors.length">
          <b-table class="table-bordered" striped hover sticky-header :items="result.errors">
            <template #cell(line)="data">
              {{ data.value }}
            </template>
            <template #cell(message)="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>
        <span v-else>No errors</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Warnings:</div>
      <div class="metadata-value">
        <div class="table-container" v-if="result.warnings && result.warnings.length">
          <b-table class="table-bordered" striped hover sticky-header :items="result.warnings">
            <template #cell(line)="data">
              {{ data.value }}
            </template>
            <template #cell(message)="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>
        <span v-else>No warnings</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Details:</div>
      <div class="metadata-value">
        <textarea
          v-if="result.error_message"
          class="form-control"
          rows="5"
          :value="result.error_message"
          readonly
          style="overflow-y: scroll;"/>
        <span v-else>No details</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Executed at:</div>
      <div class="metadata-value">{{ normalizeDate(result.updated_at) }}</div>
    </div>
    <div class="metadata-row" style="margin-top: 20px">
      <div class="metadata-label">
        Preview:
        <div>
          <small>First 50 entries</small>
        </div>
      </div>
      <div class="metadata-value preview-table">
        <b-table v-if='preview' class="table-bordered" table-class="width-auto" striped hover sticky-header="800px" :fields="fields" :items="preview">
          <template #head()="scope">
            <div class="text-nowrap">
              {{ scope.label }}
            </div>
          </template>
          <template #cell(program_type)="row">
            <div class="metadata-row">
              <div class="metadata-label program-type">DPT:</div>
              <div class="metadata-value"
                   :class="{ 'text-success': row.item.program_type.physical_therapy, 'text-danger': !row.item.program_type.physical_therapy }">
                {{ row.item.program_type.physical_therapy ? 'Yes' : 'No' }}
              </div>
            </div>
            <div class="metadata-row">
              <div class="metadata-label program-type">Bloom:</div>
              <div class="metadata-value"
                   :class="{ 'text-success': row.item.program_type.bloom, 'text-danger': !row.item.program_type.bloom }">
                {{ row.item.program_type.bloom ? 'Yes' : 'No' }}
              </div>
            </div>
            <div class="metadata-row">
              <div class="metadata-label program-type">Mind:</div>
              <div class="metadata-value"
                   :class="{ 'text-success': row.item.program_type.mind, 'text-danger': !row.item.program_type.mind }">
                {{ row.item.program_type.mind ? 'Yes' : 'No' }}
              </div>
            </div>
            <div class="metadata-row">
              <div class="metadata-label program-type">Move:</div>
              <div class="metadata-value"
                   :class="{ 'text-success': row.item.program_type.move, 'text-danger': !row.item.program_type.move }">
                {{ row.item.program_type.move ? 'Yes' : 'No' }}
              </div>
            </div>
            <div class="metadata-row">
              <div class="metadata-label program-type">On-Call:</div>
              <div class="metadata-value"
                   :class="{ 'text-success': row.item.program_type.on_call, 'text-danger': !row.item.program_type.on_call }">
                {{ row.item.program_type.on_call ? 'Yes' : 'No' }}
              </div>
            </div>
            <div class="metadata-row">
              <div class="metadata-label program-type">Academy:</div>
              <div class="metadata-value"
                   :class="{ 'text-success': row.item.program_type.academy, 'text-danger': !row.item.program_type.academy }">
                {{ row.item.program_type.academy ? 'Yes' : 'No' }}
              </div>
            </div>
          </template>
        </b-table>
        <span v-else>No preview</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Quality Check:</div>
      <div class="metadata-value">
        <b-button v-b-toggle.dry-run-quality-check class="btn btn-info btn-sm" @click="handleToggle('dryRunQualityCheck')">
          {{ toggles.dryRunQualityCheck ? 'Hide' : 'Show'}} Report
        </b-button>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label"/>
      <div class="metadata-value" v-if="result.quality_check" >
        <b-collapse class="mt-3" id="dry-run-quality-check">
          <QualityCheck :data="result.quality_check"/>
        </b-collapse>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Quarantine Report:</div>
      <div class="metadata-value">
        <b-button v-b-toggle.dry-run-quarantine-report
                  v-if="result.quarantine_report"
                  class="btn btn-info btn-sm"
                  @click="handleToggle('dryRunQuarantineReport')">
          {{ toggles.dryRunQuarantineReport ? 'Hide' : 'Show'}} Report
        </b-button>
        <span v-else>No report available.</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label"/>
      <b-collapse class="mt-3 metadata-value" v-if="result.quarantine_report"  id="dry-run-quarantine-report">
        <QuarantineReport :preview="result"/>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment';
import {
  eligibilityRelationshipOptions,
  eligibilityGenderOptions,
  EligibilityFields,
  eligibilityServiceTypeOptions,
} from '@/scripts/definitions';
import { isNullOrEmpty } from '@/helpers';
import QualityCheck from '../../../../views/Onboarding/Eligibility/QualityCheck.vue';
import QuarantineReport from '../../../../views/Onboarding/Quarantine/Eligibility/DryRun.vue';

export default {
  name: 'ClientFilePreview',
  components: {
    QualityCheck,
    QuarantineReport,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
    payers: {
      type: Array,
      default: () => [],
    },
    quarantineConfig: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    preview() {
      if (!this.result.file_preview || isNullOrEmpty(this.result.file_preview)) {
        return null;
      }
      this.result.file_preview.forEach(r => {
        r.is_dependent = JSON.parse(r.is_dependent) === true || JSON.parse(r.is_dependent) === 1;
        if (!isNullOrEmpty(r.dependent_relationship)) {
          r.dependent_relationship = eligibilityRelationshipOptions
            .find(o => o.value === r.dependent_relationship)?.text || r.dependent_relationship;
        }

        if (!isNullOrEmpty(r.gender)) {
          r.gender = eligibilityGenderOptions
            .find(o => o.value === r.gender)?.text || r.gender;
        }

        if (!isNullOrEmpty(r.program_type)) {
          const programs = JSON.parse(r.program_type);

          if (isNullOrEmpty(programs.physical_therapy)) {
            programs.physical_therapy = this.result.configuration.mapping.physical_therapy || false;
          }

          if (isNullOrEmpty(programs.prevention)) {
            programs.prevention = this.result.configuration.mapping.prevention || false;
          }

          if (isNullOrEmpty(programs.bloom)) {
            programs.bloom = this.result.configuration.mapping.bloom || false;
          }

          if (isNullOrEmpty(programs.mind)) {
            programs.mind = this.result.configuration.mapping.mind || false;
          }

          if (isNullOrEmpty(programs.move)) {
            programs.move = this.result.configuration.mapping.move || false;
          }

          if (isNullOrEmpty(programs.on_call)) {
            programs.on_call = this.result.configuration.mapping.on_call || false;
          }

          if (isNullOrEmpty(programs.academy)) {
            programs.academy = this.result.configuration.mapping.academy || false;
          }

          r.program_type = programs;
        }
      });

      return this.result.file_preview;
    },
  },
  data() {
    return {
      toggles: {
        dryRunQualityCheck: false,
        dryRunQuarantineReport: false,
      },
      quarantineData: {
        configs: null,
        report: null,
      },
      fields: EligibilityFields,
      mapping: {
        fields: [
          { key: 'column_name', label: 'Column Name' },
          { key: 'column_number', label: 'Column Number' },
        ],
        items: [],
      },
      relationship_mapping: {
        fields: [
          { key: 'key', label: 'Key' },
          { key: 'value', label: 'Value' },
        ],
        items: [],
      },
      gender_mapping: {
        fields: [
          { key: 'key', label: 'Key' },
          { key: 'value', label: 'Value' },
        ],
        items: [],
      },
      payers_mapping: {
        field: [
          { key: 'key', label: 'Key' },
          { key: 'value', label: 'Value' },
          { key: 'forAll', label: 'For All' },
        ],
        items: [],
      },
      service_type_mapping: {
        fields: [
          { key: 'key', label: 'Key' },
          { key: 'value', label: 'Value' },
        ],
        items: [],
      },
      loaded: false,
    };
  },
  beforeMount() {
    Object.keys(this.result.configuration.mapping).forEach(key => {
      if (key.includes('column_number')) {
        let val = this.result.configuration.mapping[key];
        if (val === 0 || val === '') {
          val = 'Not defined';
        }
        this.mapping.items.push({
          column_name: key.replace('column_number_', ''),
          column_number: val,
        });
      }
    });

    this.result.configuration.mapping.relationship.forEach(r => {
      this.relationship_mapping.items.push({
        key: eligibilityRelationshipOptions.find(o => o.value === r.key).text,
        value: r.value,
      });
    });

    this.result.configuration.mapping.gender.forEach(r => {
      this.gender_mapping.items.push({
        key: eligibilityGenderOptions.find(o => o.value === r.key).text,
        value: r.value,
      });
    });

    if (!isNullOrEmpty(this.result.configuration.mapping.payers)) {
      this.result.configuration.mapping.payers.forEach(r => {
        this.payers_mapping.items.push({
          key: this.payers.find(p => p.id === r.payer_id)?.label,
          value: r.value,
          forAll: r.for_all,
        });
      });
    }

    if (!isNullOrEmpty(this.result.configuration.mapping.service_type)) {
      this.result.configuration.mapping.service_type.forEach(r => {
        this.service_type_mapping.items.push({
          key: eligibilityServiceTypeOptions.find(o => o.value === r.key).text,
          value: r.value,
        });
      });
    }

    this.loaded = true;
  },
  methods: {
    normalizeDate(updatedAt) {
      return `${moment(updatedAt).format('YYYY-MM-DD HH:mm')}`;
    },
    handleToggle(toggle) {
      this.toggles[toggle] = !this.toggles[toggle];
    },
  },
};
</script>

<style scoped>
  .metadata-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
  }

  .metadata-row {
    display: flex;
    margin-bottom: 5px;
  }

  .metadata-label {
    font-weight: bold;
    margin-right: 5px;
    width: 200px;
  }

  .metadata-value {
    flex: 1;
  }

  .text-success {
    color: green;
  }

  .text-danger {
    color: red;
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 15px;
  }

  .preview-table {
    width: 100%;
    overflow-x: auto;
  }

  table {
    border-collapse: collapse;
    white-space: nowrap;
  }

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  .metadata-label.program-type {
   width: 110px;
  }
</style>

<style>
  table.width-auto {
    width: auto !important;
  }
</style>
