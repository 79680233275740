<template>
  <div class="metadata-container">
    <div class="metadata-row">
      <div class="metadata-label">Execution ID:</div>
      <div class="metadata-value">{{ result.uuid }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Success:</div>
      <div class="metadata-value"
           :class="{ 'text-success': result.success, 'text-danger': !result.success }">
        {{ result.success ? 'Yes' : 'No' }}
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">File Name:</div>
      <div class="metadata-value">{{ result.filename }}</div>
    </div>
    <div class="metadata-row" v-if="result.metadata">
      <div class="metadata-label">File Size:</div>
      <div class="metadata-value">{{ result.metadata.file_size }}</div>
    </div>
    <div class="metadata-row" v-if="result.metadata">
      <div class="metadata-label">Valid Rows:</div>
      <div class="metadata-value">{{ result.metadata.ingested_rows }}</div>
    </div>
    <div class="metadata-row" v-if="result.metadata">
      <div class="metadata-label">Invalid Rows:</div>
      <div class="metadata-value">{{ result.metadata.invalid_rows }}</div>
    </div>
    <div class="metadata-row" v-if="result.metadata">
      <div class="metadata-label">Rows with Warnings:</div>
      <div class="metadata-value">{{ result.metadata.ingested_rows_with_warnings }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Unique ID Combination:</div>
      <div class="metadata-value">{{ result.unique_id_combination }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">File delimiter:</div>
      <div class="metadata-value">{{ result.configuration.mapping.delimiter }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Override Fingerprint Value Colum Number:</div>
      <div class="metadata-value">{{ result.configuration.mapping.external_unique_id_column_number }}</div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">File Mapping:</div>
      <div class="metadata-value">
        <div class="table-container" v-if="mapping && mapping.items.length">
          <b-table class="table-bordered" striped hover sticky-header :items="mapping.items" :fields="mapping.fields">
            <template #cell(line)="data">
              <div class="text-nowrap">
                {{ data.column_name }}
              </div>
            </template>
            <template #cell(message)="data">
              <div class="text-nowrap">
                {{ data.column_number }}
              </div>
            </template>
          </b-table>
        </div>
        <span v-else>No errors</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Relationship mapping:</div>
      <div class="metadata-value">
        <div class="table-container" v-if="relationship_mapping && relationship_mapping.items.length">
          <b-table class="table-bordered" striped hover sticky-header :items="relationship_mapping.items" :fields="relationship_mapping.fields">
            <template #cell(line)="data">
              {{ data.key }}
            </template>
            <template #cell(message)="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>
        <span v-else>No mapping defined</span>
      </div>
    </div>
    <!-- TODO: test this -->
    <div class="metadata-row">
      <div class="metadata-label">Gender mapping:</div>
      <div class="metadata-value">
        <div class="table-container" v-if="gender_mapping && gender_mapping.items.length">
          <b-table class="table-bordered"
                   table-class="width-auto"
                   striped hover fixed sticky-header
                   :items="gender_mapping.items" :fields="gender_mapping.fields">
            <template #cell(line)="data">
              {{ data.key }}
            </template>
            <template #cell(message)="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>
        <span v-else>No mapping defined</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">
        Errors
        <div>
          <small>First 100 records</small>
        </div>
      </div>
      <div class="metadata-value">
        <div class="table-container" v-if="result.errors && result.errors.length">
          <b-table class="table-bordered" table-class="width-auto"  striped hover sticky-header :items="result.errors">
            <template #cell(line)="data">
              {{ data.value }}
            </template>
            <template #cell(message)="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>
        <span v-else>No errors</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Warnings:</div>
      <div class="metadata-value">
        <div class="table-container" v-if="result.warnings && result.warnings.length">
          <b-table class="table-bordered" striped hover sticky-header :items="result.warnings">
            <template #cell(line)="data">
              {{ data.value }}
            </template>
            <template #cell(message)="data">
              {{ data.value }}
            </template>
          </b-table>
        </div>
        <span v-else>No warnings</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Details:</div>
      <div class="metadata-value">
        <textarea
          v-if="result.error_message"
          class="form-control"
          rows="5"
          :value="result.error_message"
          readonly
          style="overflow-y: scroll;"/>
        <span v-else>No details</span>
      </div>
    </div>
    <div class="metadata-row">
      <div class="metadata-label">Executed at:</div>
      <div class="metadata-value">{{ result.updated_at }}</div>
    </div>
    <div class="metadata-row" style="margin-top: 20px">
      <div class="metadata-label">
        Preview:
        <div>
          <small>First 50 entries</small>
        </div>
      </div>
      <div class="metadata-value preview-table">
        <b-table class="table-bordered" striped hover sticky-header="800px" :fields="fields" :items="result.file_preview">
          <template #head()="scope">
            <div class="text-nowrap">
              {{ scope.label }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { isNullOrEmpty } from '@/helpers';
import { eligibilityRelationshipOptions, eligibilityGenderOptions } from '@/scripts/definitions';

export default {
  name: 'ClientFilePreview',
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      mapping: {
        fields: [
          { key: 'column_name', label: 'Column Name' },
          { key: 'column_number', label: 'Column Number' },
        ],
        items: [],
      },
      relationship_mapping: {
        fields: [
          { key: 'key', label: 'Key' },
          { key: 'value', label: 'Value' },
        ],
        items: [],
      },
      gender_mapping: {
        fields: [
          { key: 'key', label: 'Key' },
          { key: 'value', label: 'Value' },
        ],
        items: [],
      },
      loaded: false,
    };
  },
  beforeMount() {
    Object.values(this.result.configuration.mapping).forEach(key => {
      let val = key.column_number;
      if (isNullOrEmpty(val) || val === 0 || val === '') {
        val = 'Not defined';
      }
      this.mapping.items.push({
        column_name: this.fieldNameToDisplay(key.field_name),
        column_number: val,
      });
      this.fields.push({
        key: key.field_name,
        label: this.fieldNameToDisplay(key.field_name),
      });
    });

    this.result.configuration.normalizers.relationship.forEach(r => {
      this.relationship_mapping.items.push({
        key: eligibilityRelationshipOptions.find(o => o.value === r.key).text,
        value: r.value,
      });
    });

    this.result.configuration.normalizers.gender.forEach(r => {
      this.gender_mapping.items.push({
        key: eligibilityGenderOptions.find(o => o.value === r.key)?.text,
        value: r.value,
      });
    });

    this.loaded = true;
  },
  methods: {
    fieldNameToDisplay(fieldName) {
      return fieldName.replaceAll('_', ' ').replace(/(^|_)./g, s => s.slice(-1).toUpperCase());
    },
  },
};

</script>

<style scoped>
.metadata-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.metadata-row {
  display: flex;
  margin-bottom: 5px;
}

.metadata-label {
  font-weight: bold;
  margin-right: 5px;
  width: 200px;
}

.metadata-value {
  flex: 1;
}

.text-success {
  color: green;
}

.text-danger {
  color: red;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 15px;
}

.preview-table {
  width: 100%;
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  white-space: nowrap;
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.metadata-label.program-type {
 width: 110px;
}
</style>

<style>
table.width-auto {
  width: auto !important;
}
</style>
