<template>
  <div>
    <b-alert show variant="warning">
      By turning this feature on the system will filter eligibility by ASO group
    </b-alert>
    <b-row>
      <b-col cols="8">
        <b-form-checkbox id="use_multi-tenant_file" v-model="eligibility.multiTenant.active"
          name="use_multi-tenant_file">
          Turn on Multi-tenant eligibility
        </b-form-checkbox>
      </b-col>
      <b-col cols="4" class="text-right">
        <b-button class="mb-4" variant="primary" @click="newASOGroupModal">New ASO</b-button>
        <b-modal id="modal-aso-form" :title="aso_form.title" size="lg">
          <b-row>
            <b-col cols="4">
              <b-form-group label="ASO Group">
                <b-form-input v-model.trim="aso_form.data.aso_group" :state="!$v.aso_form.data.aso_group.$invalid">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="ASO Group Name">
                <b-form-input v-model.trim="aso_form.data.aso_group_name"
                  :state="!$v.aso_form.data.aso_group_name.$invalid">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Consortium ID">
                <b-form-input v-model.trim="aso_form.data.consortium_id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Consortium Name">
                <b-form-input v-model.trim="aso_form.data.consortium_name"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="SalesForce account_id">
                <b-form-input v-model.trim="aso_form.data.aso_salesforce_id"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row class="mt-3">
            <b-col cols="4">
              <b-form-group label="DPT | SalesForce program_id">
                <b-form-input v-model.trim="aso_form.data.dpt_salesforce_id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="DPT | Technical Launch Date">
                <b-form-datepicker v-model="aso_form.data.dpt_technical_launch_date"
                  :state="!$v.aso_form.data.dpt_technical_launch_date.$invalid"
                  :date-format-options="displayDateFormat">
                </b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="DPT | Marketing Launch Date">
                <b-form-datepicker v-model="aso_form.data.dpt_marketing_launch_date"
                  :date-format-options="displayDateFormat"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row class="mt-3">
            <b-col cols="4">
              <b-form-group label="Bloom | SalesForce program_id">
                <b-form-input v-model.trim="aso_form.data.bloom_salesforce_id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Bloom | Technical Launch Date">
                <b-form-datepicker v-model="aso_form.data.bloom_technical_launch_date"
                  :state="!$v.aso_form.data.bloom_technical_launch_date.$invalid"
                  :date-format-options="displayDateFormat">
                </b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Bloom | Marketing Launch Date">
                <b-form-datepicker v-model="aso_form.data.bloom_marketing_launch_date"
                  :date-format-options="displayDateFormat"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row class="mt-3">
            <b-col cols="4">
              <b-form-group label="Mind | SalesForce program_id">
                <b-form-input v-model.trim="aso_form.data.mind_salesforce_id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Mind | Technical Launch Date">
                <b-form-datepicker v-model="aso_form.data.mind_technical_launch_date"
                  :state="!$v.aso_form.data.mind_technical_launch_date.$invalid"
                  :date-format-options="displayDateFormat">
                </b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Mind | Marketing Launch Date">
                <b-form-datepicker v-model="aso_form.data.mind_marketing_launch_date"
                  :date-format-options="displayDateFormat"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row class="mt-3">
            <b-col cols="4">
              <b-form-group label="Move | SalesForce program_id">
                <b-form-input v-model.trim="aso_form.data.move_salesforce_id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Move | Technical Launch Date">
                <b-form-datepicker v-model="aso_form.data.move_technical_launch_date"
                  :state="!$v.aso_form.data.move_technical_launch_date.$invalid"
                  :date-format-options="displayDateFormat">
                </b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Move | Marketing Launch Date">
                <b-form-datepicker v-model="aso_form.data.move_marketing_launch_date"
                  :date-format-options="displayDateFormat"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row class="mt-3">
            <b-col cols="4">
              <b-form-group label="DPT_GO | SalesForce program_id">
                <b-form-input v-model.trim="aso_form.data.dpt_go_salesforce_id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="DPT_GO | Technical Launch Date">
                <b-form-datepicker v-model="aso_form.data.dpt_go_technical_launch_date"
                  :state="!$v.aso_form.data.dpt_go_technical_launch_date.$invalid"
                  :date-format-options="displayDateFormat"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="DPT_GO | Marketing Launch Date">
                <b-form-datepicker v-model="aso_form.data.dpt_go_marketing_launch_date"
                  :date-format-options="displayDateFormat"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <template #modal-footer>
            <b-button v-if="editing" variant="danger" class="float-right" @click="deleteASOGroup">
              delete
            </b-button>
            <b-button variant="success" class="float-right" :disabled="$v.aso_form.data.$invalid" @click="saveASOGroup">
              Save
            </b-button>
          </template>
        </b-modal>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-row v-if="eligibility.multiTenant.table.items && eligibility.multiTenant.table.items.length > 0">
          <b-col cols="12">
            <b-table striped bordered responsive small :fields="eligibility.multiTenant.table.fields"
              :items="eligibility.multiTenant.table.items">
              <template #cell(general)="data">
                <ul class="list-unstyled">
                  <li>
                    <b>ASO:</b>
                    <a class="btn-link" @click="editASOGroupModal(data.item)">
                      {{ data.item.aso_group_name }} ({{ data.item.aso_group }})
                    </a>
                  </li>
                  <li><b>Consortium:</b> {{ data.item.consortium_name }} ({{ data.item.consortium_id }})</li>
                  <li><b>SF account:</b> {{ data.item.aso_salesforce_id }}</li>
                </ul>
              </template>
              <template #cell(dpt)="data">
                <ul class="list-unstyled">
                  <li><b>SF program:</b> {{ data.item.dpt_salesforce_id }}</li>
                  <li><b>Tech launch:</b> {{ dateToDisplay(data.item.dpt_technical_launch_date) }}</li>
                  <li><b>Marketing launch:</b> {{ dateToDisplay(data.item.dpt_marketing_launch_date) }}</li>
                </ul>
              </template>
              <template #cell(bloom)="data">
                <ul class="list-unstyled">
                  <li><b>SF program:</b> {{ data.item.bloom_salesforce_id }}</li>
                  <li><b>Tech launch:</b> {{ dateToDisplay(data.item.bloom_technical_launch_date) }}</li>
                  <li><b>Marketing launch:</b> {{ dateToDisplay(data.item.bloom_marketing_launch_date) }}</li>
                </ul>
              </template>
              <template #cell(mind)="data">
                <ul class="list-unstyled">
                  <li><b>SF program:</b> {{ data.item.mind_salesforce_id }}</li>
                  <li><b>Tech launch:</b> {{ dateToDisplay(data.item.mind_technical_launch_date) }}</li>
                  <li><b>Marketing launch:</b> {{ dateToDisplay(data.item.mind_marketing_launch_date) }}</li>
                </ul>
              </template>
              <template #cell(move)="data">
                <ul class="list-unstyled">
                  <li><b>SF program:</b> {{ data.item.move_salesforce_id }}</li>
                  <li><b>Tech launch:</b> {{ dateToDisplay(data.item.move_technical_launch_date) }}</li>
                  <li><b>Marketing launch:</b> {{ dateToDisplay(data.item.move_marketing_launch_date) }}</li>
                </ul>
              </template>
              <template #cell(dpt_go)="data">
                <ul class="list-unstyled">
                  <li><b>SF program:</b> {{ data.item.dpt_go_salesforce_id }}</li>
                  <li><b>Tech launch:</b> {{ dateToDisplay(data.item.dpt_go_technical_launch_date) }}</li>
                  <li><b>Marketing launch:</b> {{ dateToDisplay(data.item.dpt_go_marketing_launch_date) }}</li>
                </ul>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <!-- Pagination -->
        <b-row v-if="eligibility.multiTenant.table.items && eligibility.multiTenant.table.items.length > 0">
          <b-col cols="12">
            <div class="col-12 d-flex justify-content-center">
              <b-pagination :disabled="pagination.loadingPage" @change="changePage" v-model="pagination.currentPage"
                :total-rows="pagination.totalRows" :per-page="pagination.itemsPerPage" size="md">
              </b-pagination>
            </div>
          </b-col>
        </b-row>

        <b-card style="margin-top:20px">
          <h6>CSV</h6>
          <b-alert show variant="warning">Uploading a CSV replaces all current configured ASO groups.</b-alert>
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col cols="8">
                  <b-form-file id="input-multi-tenant-file" v-model="eligibility.multiTenant.file"
                    placeholder="Upload CSV"
                    :disabled="!$acl.check('onboarding.eligibility.multiTenant.edit') || !eligibility.multiTenant.active"
                    required drop-placeholder="Drop file here..." accept=".csv" />
                </b-col>
                <b-col cols="4">
                  <b-button class="float-right" :disabled="!eligibility.multiTenant.active" variant="warning"
                    @click="uploadMultiTenantFile">
                    Upload
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-button variant="btn btn-success" @click.prevent="downloadMultiTenantFile">
                Download CSV with current version
              </b-button>
            </b-col>
            <b-col cols="12" class="mt-3">
              <a class="btn btn-primary" download :disabled="!eligibility.multiTenant.active"
                href="https://cdn.swordhealth.tech/assets/assets/files/template_aso_groups/template_aso_groups_v2.csv">
                Download empty template
              </a>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import pagination from '@/mixins/lists/pagination';

const DATA_FIELDS = [
  'start_date',
  'bloom_marketing_launch_date',
  'bloom_technical_launch_date',
  'dpt_go_marketing_launch_date',
  'dpt_go_technical_launch_date',
  'dpt_marketing_launch_date',
  'dpt_technical_launch_date',
  'move_marketing_launch_date',
  'move_technical_launch_date',
  'mind_marketing_launch_date',
  'mind_technical_launch_date',
];

export default {
  components: {},
  mixins: [
    pagination,
  ],
  props: {
    client: {
      type: Object,
      default: () => { },
    },
    eligibility: {
      type: Object,
      default: () => { },
    },
    aso_form: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      editing: false,
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        totalRows: 0,
        loadingPage: false,
      },
      displayDateFormat: { year: 'numeric', month: 'numeric', day: 'numeric' },
    };
  },
  computed: {},
  validations() {
    return {
      aso_form: {
        data: {
          aso_group: { required },
          aso_group_name: { required },
          dpt_technical_launch_date: {
            required(value) {
              return this.aso_form.data.bloom_technical_launch_date
                || this.aso_form.data.move_technical_launch_date
                || this.aso_form.data.dpt_go_technical_launch_date
                || this.aso_form.data.mind_technical_launch_date
                || required(value);
            },
          },
          bloom_technical_launch_date: {
            required(value) {
              return this.aso_form.data.dpt_technical_launch_date
                || this.aso_form.data.move_technical_launch_date
                || this.aso_form.data.mind_technical_launch_date
                || this.aso_form.data.dpt_go_technical_launch_date
                || required(value);
            },
          },
          move_technical_launch_date: {
            required(value) {
              return this.aso_form.data.dpt_technical_launch_date
                || this.aso_form.data.bloom_technical_launch_date
                || this.aso_form.data.mind_technical_launch_date
                || this.aso_form.data.dpt_go_technical_launch_date
                || required(value);
            },
          },
          dpt_go_technical_launch_date: {
            required(value) {
              return this.aso_form.data.dpt_technical_launch_date
                || this.aso_form.data.bloom_technical_launch_date
                || this.aso_form.data.mind_technical_launch_date
                || this.aso_form.data.move_technical_launch_date
                || required(value);
            },
          },
          mind_technical_launch_date: {
            required(value) {
              return this.aso_form.data.dpt_technical_launch_date
                || this.aso_form.data.dpt_go_technical_launch_date
                || this.aso_form.data.mind_technical_launch_date
                || this.aso_form.data.move_technical_launch_date
                || required(value);
            },
          },
        },
      },
    };
  },
  watch: {
  },
  async mounted() {
    await this.fetchMultiTenant();
  },
  methods: {
    newASOGroupModal() {
      this.aso_form.title = 'New ASO Group';
      this.aso_form.data = {};
      this.editing = false;
      this.$bvModal.show('modal-aso-form');
    },
    editASOGroupModal(item) {
      this.aso_form.title = `Edit ASO Group ${item.aso_group}`;
      const data = { ...item };
      this.aso_form.data = data;
      this.editing = true;
      this.$bvModal.show('modal-aso-form');
    },
    fetchMultiTenant() {
      this.pagination.loadingPage = true;
      const page = this.pagination.currentPage - 1;
      const query = [
        `limit=${this.pagination.itemsPerPage}`,
        `offset=${page * this.pagination.itemsPerPage}`,
      ];

      axios.get(`v1/clients/${this.client.id}/multi-tenants?${query.join('&')}`).then(resp => {
        this.eligibility.multiTenant.table.items = resp.data.data;
        this.pagination.totalRows = resp.data.pagination.total;
        if (resp.data.data && resp.data.data.length > 0) {
          this.eligibility.multiTenant.fileName = resp.data.data[0].file_name;
        }
      }).catch(error => {
        if (error.response.status === 404) {
          this.eligibility.multiTenant.table.items = [];
          return;
        }
        this.$noty.error(this.formatAsoGroupError('Unable to fetch multi tenant eligibility file:\n', error));
      }).finally(() => {
        this.pagination.loadingPage = false;
      });
    },
    changePage(page) {
      this.pagination.currentPage = page;
      this.fetchMultiTenant();
    },
    saveASOGroup() {
      // convert dates to string
      const data = this.convertDatesTOISOString(this.aso_form);
      let method = 'post';
      let url = `v1/clients/${this.client.id}/multi-tenants`;

      if (this.editing) {
        method = 'put';
        url = `v1/clients/${this.client.id}/multi-tenants/${data.id}`;
      }

      axios.request({ method, url, data }).then(() => {
        this.$noty.success('ASO Group saved with success');
        this.$bvModal.hide('modal-aso-form');
        this.fetchMultiTenant();
      }).catch(error => {
        console.error(error);
        this.$noty.error(this.formatAsoGroupError('Unable to save aso group:\n', error));
      });
    },
    deleteASOGroup() {
      const { data } = this.aso_form;
      axios.delete(`v1/clients/${this.client.id}/multi-tenants/${data.aso_group}`).then(() => {
        this.$noty.success('ASO Group deleted with success');
        this.$bvModal.hide('modal-aso-form');
        this.fetchMultiTenant();
      }).catch(error => {
        console.error(error);
        this.$noty.error(this.formatAsoGroupError('Unable to delete aso group:\n', error));
      });
    },
    async uploadMultiTenantFile() {
      if (!this.eligibility.multiTenant.file) {
        return;
      }

      if (this.eligibility.multiTenant.file.name.split('.').pop() !== 'csv') {
        this.$noty.error('Please select a CSV file type');

        return;
      }

      const proceed = await this.$bvModal.msgBoxConfirm(
        'By uploading this file you will be replacing the actual multi-tenant configuration. Are you sure you want to proceed?',
        {
          title: 'Multi-tenant eligibility configuration',
          okTitle: 'Proceed',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        },
      );

      if (!proceed) {
        return;
      }

      try {
        this.loading = true;

        const formData = new FormData();
        formData.append('file', this.eligibility.multiTenant.file);

        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };

        await axios.post(`v1/clients/${this.client.id}/multi-tenants/upload`, formData, config);
        this.$noty.success('Multi-tenant eligibility file uploaded with success');
      } catch (error) {
        console.error(error);
        this.$noty.error(this.formatAsoGroupError('Unable to upload multi tenant eligibility file:\n', error));
      } finally {
        await this.fetchMultiTenant();
        this.loading = false;
      }
    },
    async downloadMultiTenantFile() {
      try {
        const { data } = await axios.get(`v1/clients/${this.client.id}/multi-tenants/download`);

        const fileURL = window.URL.createObjectURL(new Blob([ data ]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', this.eligibility.multiTenant.fileName || `${this.client.id}_aso_groups.csv`);
        document.body.appendChild(fileLink);

        fileLink.click();
        URL.revokeObjectURL(fileLink.href);
      } catch (error) {
        console.error(error);
        this.$noty.error(this.formatAsoGroupError('Unable to download multi tenant eligibility file:\n', error));
      }
    },
    formatAsoGroupError(defaultMessage, err) {
      let errorDetail = '';
      if (err.response && err.response.data && err.response.data.error?.detail) {
        errorDetail = err.response.data.error.detail;
      }
      return defaultMessage + errorDetail;
    },
    convertDatesTOISOString(form) {
      const { data } = form;
      DATA_FIELDS.forEach(f => {
        data[f] = data[f] ? new Date(data[f]).toISOString() : null;
      });
      return data;
    },
    dateToDisplay(date) {
      return date && date.length > 0 ? new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' }) : null;
    },
  },
};
</script>
